import InfoBlog from "components/InfoBlog/InfoBlog";
import Header from "components/Header/Header";
import Feature from "components/Feature/Feature";

const Home = () => {
  return (
    <>
      <Header />
      <Feature />
      <InfoBlog />
    </>
  );
}

export default Home;
