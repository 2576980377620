import { useState } from "react";
import { useForm } from "react-hook-form";

function Contact() {
  const [showAlert, setShowAlert] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitted, isSubmitSuccessful },
  } = useForm();

  const onSubmit = async (data: any) => {
    const res = await fetch("/api/sendEmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    setShowAlert(true);

    if (res.status === 200) {
      return console.log("resolved");
    }

    return console.log("rejected");
  };

  return (
    <section className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-20">
      <div className="mt-10 sm:mt-0">
        <div className="flex flex-col text-center w-full mb-12">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-4xl md:mb-12">
            <h1 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
              Kontakt
            </h1>
          </div>
          <div className="lg:flex">
            <div className="lg:flex-1">
              Klaudie Židová <br />
              Praskova 1 <br />
              Krnov 79401 <br />
              <br /> IČO: 08582211
              <br />
              <br />
            </div>
            <div className="lg:flex-1">
              <iframe
                title="mapa"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2559.815194955691!2d17.713887115990367!3d50.08974682116987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47118274a0af89d1%3A0x3c6200fccb8f408a!2sPraskova%201%2C%20794%2001%20Krnov!5e0!3m2!1scs!2scz!4v1631901996750!5m2!1scs!2scz"
                width="100%"
                height="450"
                style={{ border: 0, margin: "0 auto" }}
                loading="lazy"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col text-center w-full mb-12">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
            Napište nám
          </h1>
        </div>
        <div className="lg:w-1/2 md:w-2/3 mx-auto">
          {!isSubmitSuccessful && !showAlert && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-wrap -m-2">
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="name"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Jméno a Příjmení
                    </label>
                    <input
                      type="text"
                      id="firstname"
                      className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-cyan-500 focus:bg-white focus:ring-2 focus:ring-cyan-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      {...register("firstname", {
                        required: true,
                        maxLength: 80,
                      })}
                    />
                    <p className="text-red-500 text-xs italic">
                      {errors.firstname?.message}
                    </p>
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label className="leading-7 text-sm text-gray-600">
                      E-mail
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-cyan-500 focus:bg-white focus:ring-2 focus:ring-cyan-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      {...register("email", {
                        required: true,
                        pattern: /^\S+@\S+$/i,
                      })}
                    />
                    <p className="text-red-500 text-xs italic">
                      {errors.email?.message}
                    </p>
                  </div>
                </div>
                <div className="p-2 w-full">
                  <div className="relative">
                    <label className="leading-7 text-sm text-gray-600">
                      Zpráva
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-cyan-500 focus:bg-white focus:ring-2 focus:ring-cyan-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                    ></textarea>
                  </div>
                </div>
                <div className="p-2 w-full">
                  <button
                    disabled={isSubmitted}
                    type="submit"
                    className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-cyan-accent-400 hover:bg-cyan-accent-700 focus:shadow-outline focus:outline-none"
                  >
                    Odeslat
                  </button>
                </div>
              </div>
            </form>
          )}
          {isSubmitSuccessful && showAlert ? (
            <div
              className={
                "text-white px-6 py-4 border-0 rounded relative mb-4 bg-cyan-500"
              }
            >
              <span className="text-xl inline-block mr-5 align-middle">
                <i className="fas fa-bell" />
              </span>
              <span className="inline-block align-middle mr-8">
                Děkujeme. Ozveme se Vám!
              </span>
              <button
                className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
                onClick={() => {
                  setShowAlert(false);
                  reset();
                }}
              >
                <span>×</span>
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
}

export default Contact;
