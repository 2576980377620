import Travel from "assets/images/travel.jpg";
import { useState } from "react";
import { useForm } from "react-hook-form";

const Header = () => {
  const [showAlert, setShowAlert] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted, isSubmitSuccessful },
  } = useForm();

  const onSubmit = async (data: any) => {
    const res = await fetch("/api/sendEmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (res.status === 200) {
      return console.log("resolved");
    }

    return console.log("rejected");
  };

  return (
    <div className="relative">
      <img
        src={Travel}
        className="absolute inset-0 object-cover w-full h-full"
        alt=""
      />
      <div className="relative bg-opacity-75 bg-cyan-accent-700">
        <svg
          className="absolute inset-x-0 bottom-0 text-white"
          viewBox="0 0 1160 163"
        >
          <path
            fill="currentColor"
            d="M-164 13L-104 39.7C-44 66 76 120 196 141C316 162 436 152 556 119.7C676 88 796 34 916 13C1036 -8 1156 2 1216 7.7L1276 13V162.5H1216C1156 162.5 1036 162.5 916 162.5C796 162.5 676 162.5 556 162.5C436 162.5 316 162.5 196 162.5C76 162.5 -44 162.5 -104 162.5H-164V13Z"
          />
        </svg>
        <div className="relative px-4 py-16 mx-auto overflow-hidden sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="flex flex-col items-center justify-between xl:flex-row">
            <div className="w-full max-w-xl mb-12 xl:mb-0 xl:pr-16 xl:w-7/12">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none">
                Naše nabídka
                <br className="hidden md:block" />
              </h2>
              <p className="max-w-xl mb-4 text-base text-gray-200 md:text-lg">
                Chtěli by jste na dovolenou bez cestovky, nemáte čas ji
                naplánovat nebo vás to nebaví ?
              </p>
              <a
                href="/request"
                aria-label=""
                className="inline-flex items-center font-semibold tracking-wider transition-colors duration-200 text-teal-accent-400 hover:text-teal-accent-700"
              >
                Poptávka dovolené
                <svg
                  className="inline-block w-3 ml-2"
                  fill="currentColor"
                  viewBox="0 0 12 12"
                >
                  <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                </svg>
              </a>
            </div>
            <div className="w-full xl:h-524 max-w-xl xl:px-8 xl:w-5/12">
              {!isSubmitSuccessful && (
                <div className="bg-white rounded shadow-2xl p-7 sm:p-10">
                  <h3 className="mb-4 text-xl font-semibold sm:text-center sm:mb-6 sm:text-2xl">
                    Kontaktujte nás
                  </h3>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-1 sm:mb-2">
                      <label
                        htmlFor="firstname"
                        className="inline-block mb-1 font-medium"
                      >
                        Jméno
                      </label>
                      <input
                        placeholder="Jan"
                        required
                        type="text"
                        className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-cyan-accent-400 focus:outline-none focus:shadow-outline"
                        id="firstname"
                        {...register("firstname", {
                          required: true,
                          maxLength: 80,
                        })}
                      />
                      <p className="text-red-500 text-xs italic">
                        {errors.firstname?.message}
                      </p>
                    </div>
                    <div className="mb-1 sm:mb-2">
                      <label
                        htmlFor="lastName"
                        className="inline-block mb-1 font-medium"
                      >
                        Příjmení
                      </label>
                      <input
                        placeholder="Novák"
                        required
                        type="text"
                        className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-cyan-accent-400 focus:outline-none focus:shadow-outline"
                        id="lastName"
                        {...register("lastName", {
                          required: true,
                          maxLength: 100,
                        })}
                      />
                      <p className="text-red-500 text-xs italic">
                        {errors.lastName?.message}
                      </p>
                    </div>
                    <div className="mb-1 sm:mb-2">
                      <label
                        htmlFor="email"
                        className="inline-block mb-1 font-medium"
                      >
                        E-mail
                      </label>
                      <input
                        placeholder="jan.novak@domena.cz"
                        required
                        type="text"
                        className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-cyan-accent-400 focus:outline-none focus:shadow-outline"
                        id="email"
                        {...register("email", {
                          required: true,
                          pattern: /^\S+@\S+$/i,
                        })}
                      />
                      <p className="text-red-500 text-xs italic">
                        {errors.email?.message}
                      </p>
                    </div>
                    <div className="mt-4 mb-2 sm:mb-4">
                      <button
                        type="submit"
                        disabled={isSubmitted}
                        className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-cyan-accent-400 hover:bg-cyan-accent-700 focus:shadow-outline focus:outline-none"
                      >
                        Dozvědět se více
                      </button>
                    </div>
                    <p className="text-xs text-gray-600 sm:text-sm">
                      Respektujeme vaše soukromí.
                    </p>
                  </form>
                </div>
              )}
              {isSubmitSuccessful && showAlert ? (
                <div
                  className={
                    "text-white px-6 py-4 border-0 rounded relative mb-4 bg-cyan-500"
                  }
                >
                  <span className="text-xl inline-block mr-5 align-middle">
                    <i className="fas fa-bell" />
                  </span>
                  <span className="inline-block align-middle mr-8">
                    Děkujeme. Ozveme se Vám!
                  </span>
                  <button
                    className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
                    onClick={() => setShowAlert(false)}
                  >
                    <span>×</span>
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
