import { useState } from "react";

const Nav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <div className="relative flex grid items-center grid-cols-2 lg:grid-cols-3">
        <ul className="flex items-center hidden space-x-8 lg:flex">
          <li>
            <a
              href="/request"
              aria-label="Poptávka"
              title="Poptávka"
              className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-cyan-accent-400"
            >
              Poptávka
            </a>
          </li>
          <li>
            <a
              href="/about-us"
              aria-label="O nás"
              title="O nás"
              className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-cyan-accent-400"
            >
              O&nbsp;nás
            </a>
          </li>
          <li>
            <a
              href="/blog"
              aria-label="Blog"
              title="Blog"
              className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-cyan-accent-400"
            >
              Blog
            </a>
          </li>
          <li>
            <a
              href="/contact"
              aria-label="Kontakt"
              title="Kontakt"
              className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-cyan-accent-400"
            >
              Kontakt
            </a>
          </li>
        </ul>
        <a
          href="/"
          aria-label="Travel Klaudia"
          title="Travel Klaudia"
          className="inline-flex items-center lg:mx-auto"
        >
          <svg
            className="w-8 text-cyan-accent-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
          <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">
            Travel Klaudia
          </span>
        </a>
        <ul className="flex items-center hidden ml-auto space-x-8 lg:flex">
          <li>
            <a
              href="tel:+420737038579"
              aria-label="Telefon"
              title="Telefon"
              className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-cyan-accent-400"
            >
              +420 737 038 579
            </a>
            <br />
            <a
              href="mailto:info@travelklaudia.cz"
              aria-label="Email"
              title="Email"
              className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-cyan-accent-400"
            >
              info@travelklaudia.cz
            </a>
          </li>
        </ul>
        <div className="ml-auto lg:hidden">
          <button
            aria-label="Open Menu"
            title="Open Menu"
            className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-cyan-50 focus:bg-cyan-50"
            onClick={() => setIsMenuOpen(true)}
          >
            <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
              />
              <path
                fill="currentColor"
                d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
              />
              <path
                fill="currentColor"
                d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
              />
            </svg>
          </button>
          {isMenuOpen && (
            <div className="absolute top-0 left-0 w-full z-10">
              <div className="p-5 bg-white border rounded shadow-sm">
                <div className="flex items-center justify-between mb-4">
                  <div>
                    <a
                      href="/"
                      aria-label="Company"
                      title="Company"
                      className="inline-flex items-center"
                    >
                      <svg
                        className="w-8 text-cyan-accent-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                        />
                      </svg>
                      <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">
                        Travel Klaudia
                      </span>
                    </a>
                  </div>
                  <div>
                    <button
                      aria-label="Close Menu"
                      title="Close Menu"
                      className="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <nav>
                  <ul className="space-y-4">
                    <li>
                      <a
                        href="/request"
                        aria-label="Poptávka"
                        title="Poptávka"
                        className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-cyan-accent-400"
                      >
                        Poptávka
                      </a>
                    </li>
                    <li>
                      <a
                        href="/about-us"
                        aria-label="O nás"
                        title="O nás"
                        className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-cyan-accent-400"
                      >
                        O nás
                      </a>
                    </li>
                    <li>
                      <a
                        href="/blog"
                        aria-label="Blog"
                        title="Blog"
                        className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-cyan-accent-400"
                      >
                        Blog
                      </a>
                    </li>
                    <li>
                      <a
                        href="/contact"
                        aria-label="Kontakt"
                        title="Kontakt"
                        className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-cyan-accent-400"
                      >
                        Kontakt
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Nav;
