import Maledivy from 'assets/images/maledivy.jpg';
import Thajsko from 'assets/images/thajsko.jpg';
import Bali from 'assets/images/bali.jpg';

const InfoBlog = () => {
  return (
    <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-20">
      <div className="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-sm">
          <img
            src={Maledivy}
            className="object-cover w-full h-64"
            alt=""
          />
          <div className="p-5 border border-t-0">
            <p className="mb-3 text-xs font-semibold tracking-wide uppercase">
              <a
                href="/"
                className="transition-colors duration-200 text-blue-gray-900 hover:text-cyan-accent-700"
                aria-label="Kategorie"
                title="cestování"
              >
                cestování
              </a>
              <span className="text-gray-600">— 1 Prosince 2020</span>
            </p>
            <a
              href="/"
              aria-label="Kategorie"
              title="Maledivy"
              className="inline-block mb-3 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-cyan-accent-700"
            >
              Maledivy
            </a>
            <p className="mb-2 text-gray-700">
              Maledivská republika, ostrovní stát na jihu Asie v Indickém oceánu. Ostrovy jsou pokryté chlebovníkovými a palmovými háji. Jsou lemovány písčitými plážemi a průzračnými lagunami.
            </p>
            <a
              href="/"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-cyan-accent-400 hover:text-cyan-800"
            >
              Více informací
            </a>
          </div>
        </div>
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-sm">
          <img
            src={Thajsko}
            className="object-cover w-full h-64"
            alt=""
          />
          <div className="p-5 border border-t-0">
            <p className="mb-3 text-xs font-semibold tracking-wide uppercase">
              <a
                href="/"
                className="transition-colors duration-200 text-blue-gray-900 hover:text-cyan-accent-700"
                aria-label="Kategorie"
                title="cestování"
              >
                cestování
              </a>
              <span className="text-gray-600">— 28 Leden 2021</span>
            </p>
            <a
              href="/"
              aria-label="Kategorie"
              title="Thajsko"
              className="inline-block mb-3 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-cyan-accent-700"
            >
              Thajsko
            </a>
            <p className="mb-2 text-gray-700">
              Mezi hlavní turistické lokality patří hlavní město Bangkok, přímořské město Pattaya, Čiang Mai, Phuket, Ko Čang a provincie Krabi. 
            </p>
            <a
              href="/"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-cyan-accent-400 hover:text-cyan-800"
            >
              Více informací
            </a>
          </div>
        </div>
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-sm">
          <img
            src={Bali}
            className="object-cover w-full h-64"
            alt=""
          />
          <div className="p-5 border border-t-0">
            <p className="mb-3 text-xs font-semibold tracking-wide uppercase">
              <a
                href="/"
                className="transition-colors duration-200 text-blue-gray-900 hover:text-cyan-accent-700"
                aria-label="Kategorie"
                title="cestování"
              >
                cestování
              </a>
              <span className="text-gray-600">— 2 Únor 2021</span>
            </p>
            <a
              href="/"
              aria-label="Kategorie"
              title="Bali"
              className="inline-block mb-3 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-cyan-accent-700"
            >
              Bali
            </a>
            <p className="mb-2 text-gray-700">
              Bali je součástí Korálového trojúhelníku, oblasti s velkou rozmanitostí mořských živočichů, zvlášť ryb a želv. Jen v této oblasti lze nalézt více než 500 druhů korálů budujících útesy.
            </p>
            <a
              href="/"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-cyan-accent-400 hover:text-cyan-800"
            >
              Více informací
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoBlog;