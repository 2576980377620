function Terms() {
  return (
    <section className="text-gray-600 body-font relative">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-12">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
            Obchodní podmínky
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-left">
            <div dir="ltr">
              <h2>
                <b>I. Úvodní ustanovení</b>
              </h2>
              <br />
              <p>
                1.1 Tyto všeobecné obchodní podmínky (dále také jako „<b>VOP</b>
                ”) upravují průběh jednání o uzavření smlouvy o zájezdu mezi
                cestovní kanceláří a zákazníkem, práva a povinnosti zákazníka a
                cestovní kanceláře, jakož i podmínky poskytování služeb
                cestovního ruchu. Tyto VOP jsou nedílnou součástí smlouvy o
                zájezdu uzavřené mezi CK a zákazníkem. V&nbsp;případě, že
                smlouva o zájezdu stanoví práva a povinnosti CK a/nebo zákazníka
                odlišně od úpravy těchto VOP, použije se ustanovení smlouvy o
                zájezdu.
              </p>
              <br />
              <h2>
                <b>II. Definice základních pojmů</b>
              </h2>
              <br />
              <p>
                2.1 Cestovní kanceláří &nbsp;se pro účely těchto všeobecných
                obchodních podmínek rozumí cestovní kancelář{" "}
                <a
                  href="http://travelklaudie.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  travelklaudie.com
                </a>
                , sídlem Praskova 1, Krnov 79401, IČ 08582211, zapsaná v OR
                vedeného Krajským soudem v Ostravě.
              </p>
              <br />
              <p>
                2.2 Internetovými stránkami
                <span>
                  &nbsp;se pro účely těchto všeobecných obchodních podmínek
                  rozumí internetové stránky&nbsp;
                </span>
                <span>CK</span>
                <span>
                  &nbsp;dostupné na{" "}
                  <a
                    href="http://www.travelklaudia.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.travelklaudia.com
                  </a>
                  .&nbsp;
                </span>
                <span>
                  Informa
                  <wbr />
                  ce uvedené na jakýchkoliv jiných stránkách, a to včetně
                  sociálních sítí, nejsou nabídkou&nbsp;
                </span>
                <span>CK</span>
                <span>&nbsp;ani nemohou&nbsp;</span>
                <span>CK</span>
                <span>
                  &nbsp;ve vztahu k&nbsp;zákazníkům nikterak zavazovat.
                </span>
              </p>
              <br />
              <p>
                2.3 Katalogem&nbsp; se pro účely těchto všeobecných obchodních
                podmínek rozumí katalog&nbsp;
                <span>CK</span>, v&nbsp;němž jsou nabízeny zájezdy.
              </p>
              <br />

              <p>
                2.4 Obchodním zástupcem&nbsp; se pro účely této smlouvy považuje
                fyzická nebo právnická osoba, kterou&nbsp;
                <span>CK</span>
                &nbsp;zmocnila k&nbsp;uzavírání smluv o zájezdu, přebírání
                finančních prostředků, popřípadě k&nbsp;jiným právním jednáním
                souvisejícím s&nbsp;uzavíráním smlouvy o zájezdu.
              </p>
              <br />

              <p>
                2.5 Objednatelem&nbsp; se pro účely těchto všeobecných
                obchodních podmínek rozumí zákazník, který uzavřel s&nbsp;CK
                smlouvu o zájezdu. Objednatel je povinen před podpisem smlouvy o
                zájezdu získat pokyn k&nbsp;uzavření této smlouvy od všech osob,
                v&nbsp;jejichž prospěch budou služby cestovního ruchu na základě
                uzavírané smlouvy poskytovány. V&nbsp;případě, že je zákazníkem
                osoba mladší 18 let, pak je objednatel povinen získat pokyn
                k&nbsp;uzavření smlouvy ve prospěch této osoby ze strany jeho
                zákonných zástupců.
              </p>
              <br />

              <ol>
                <li>
                  2.6&nbsp; Pořadatelem&nbsp; se pro účely těchto všeobecných
                  obchodních podmínek rozumí&nbsp;
                  <span>CK</span>.
                </li>
              </ol>
              <br />

              <p>
                2.7 Službou cestovního ruchu &nbsp;se pro účely těchto
                všeobecných obchodních podmínek rozumí:
              </p>
              <p>a) doprava zákazníka,</p>
              <p>
                b) ubytování, které není součástí dopravy a&nbsp;není určeno
                k&nbsp;účelům bydlení,
              </p>
              <p>
                c) nájem automobilu, motocyklu nebo jiného motorového vozidla,
                nebo
              </p>
              <p>
                d) jiná služba z&nbsp;oblasti cestovního ruchu, která není ze
                své podstaty součástí některé ze služeb cestovního ruchu
                uvedených v&nbsp;písmenu a), b) nebo c), zejména prodej
                vstupenek na kulturní nebo sportovní události, pořádání výletů,
                prohlídek s&nbsp;průvodcem, prodej skipasů nebo nájem
                sportovního vybavení.
              </p>
              <br />

              <p>
                2.8 Přepravními podmínkami&nbsp; se pro účely těchto všeobecných
                obchodních podmínek rozumí podmínky subjektu, který zajišťuje
                přepravu zákazníků, zavazadel a věcí. Přepravní podmínky jsou
                dostupné na internetových stránkách přepravních společností
                poskytujících přepravu dle uzavřené smlouvy o zájezdu. Zákazník
                podpisem smlouvy o zájezdu prohlašuje, že se s&nbsp;těmito
                přepravními podmínkami seznámil a že s nimi souhlasí.
              </p>
              <br />

              <p>
                2.9 Smlouvou o zájezdu&nbsp; se pro účely těchto všeobecných
                obchodních podmínek rozumí smlouva uzavřená mezi&nbsp;
                <span id="m_6832594885467667968gmail-highlight12">CK</span>
                &nbsp;a zákazníkem, popřípadě některým ze zákazníků
                (objednatelem), v&nbsp;níž jsou sjednány práva a
                povinnosti&nbsp;
                <span id="m_6832594885467667968gmail-highlight13">CK</span>
                &nbsp;a zákazníka, jakož i rozsah služeb cestovního ruchu, které
                tvoří zájezd. Součástí smlouvy o zájezdu jsou tyto všeobecné
                obchodní podmínky, přepravní podmínky, jakož i další zvláštní
                podmínky, které jsou přiloženy ke smlouvě o zájezdu a tvoří její
                součást.
              </p>
              <br />

              <p>
                2.10 Zájezdem&nbsp; se pro účely těchto všeobecných obchodních
                podmínek rozumí soubor služeb cestovního ruchu, které jsou
                nabízeny v&nbsp;katalogu&nbsp;
                <span id="m_6832594885467667968gmail-highlight14">CK</span>.
              </p>
              <br />

              <p>
                2.11 Zákazníkem&nbsp; se pro účely těchto všeobecných obchodních
                podmínek rozumí osoba:
              </p>
              <p>
                a) která má v&nbsp;úmyslu uzavřít nebo uzavře s&nbsp;cestovní
                kanceláří smlouvu o&nbsp;zájezdu, nebo
              </p>
              <p>
                b) v&nbsp;jejíž prospěch byla některá z&nbsp;těchto smluv
                uzavřena, anebo
              </p>
              <p>c) které byla smlouva postoupena.</p>
              <p>
                V&nbsp;případě, že je podmínkou pro účast na zájezdu/získání
                slevy dosažení určitého věku zákazníka, pak tohoto věku musí
                zákazník dosáhnout před započetím první ze služeb cestovního
                ruchu. V&nbsp;případě podmínky nedosažení určitého věku, pak
                zákazník nesmí tohoto věku dosáhnout během celého zájezdu.
              </p>
              <br />

              <p>
                2.12 Zvláštními podmínkami&nbsp; se pro účely těchto všeobecných
                obchodních podmínek rozumí práva a povinnosti&nbsp;
                <span>CK</span>
                &nbsp;a zákazníka ve vztahu ke konkrétnímu zájezdu.
                V&nbsp;případě rozdílů mezi zvláštními podmínkami a podmínkami
                uvedenými v&nbsp;těchto VOP či přepravních podmínkách se použijí
                zvláštní podmínky.
              </p>
              <br />

              <h2>
                <b>III. Jednání o uzavření smlouvy o zájezdu</b>
              </h2>
              <br />
              <p>
                3.1&nbsp;
                <span>CK</span>
                &nbsp;informuje zákazníka před uzavřením smlouvy o zájezdu o
                všech podstatných náležitostech smlouvy o zájezdu,
                k&nbsp;jejímuž uzavření jejich vzájemná jednání směřují.&nbsp;
                <span>CK</span>
                &nbsp;zpravidla informuje zákazníky prostřednictvím katalogu,
                popřípadě informací uvedených na svých internetových stránkách.
                V&nbsp;případě, že informace uvedené v&nbsp;katalogu a na
                internetových stránkách nejsou shodné, použijí se údaje uvedené
                na internetových stránkách.
              </p>
              <br />
              <p>
                3.2&nbsp;
                <span>CK</span>
                &nbsp;dále v&nbsp;rámci těchto kontraktačních jednání předá
                zákazníkovi informační formulář dle vyhlášky č. 122/2018 Sb., o
                způsobu právní ochrany zákazníka. V&nbsp;případě jakýchkoliv
                nejasností zákazníka, je&nbsp;
                <span id="m_6832594885467667968gmail-highlight19">CK</span>
                &nbsp;povinna mu tyto nesrovnalosti vysvětlit.
              </p>
              <br />
              <p>
                3.3 Rozsah a kvalita služeb cestovního ruchu poskytovaných
                v&nbsp;rámci konkrétního zájezdu je definována v&nbsp;popisu
                zájezdu uvedeného v&nbsp;katalogu.
              </p>
              <br />
              <p>
                3.4 V&nbsp;případě, že je v&nbsp;katalogu, popřípadě na
                internetových stránkách, u konkrétního zájezdu uvedeno, že se
                jedná o zájezd „na vyžádání”, pak&nbsp;
                <span id="m_6832594885467667968gmail-highlight20">CK</span>
                &nbsp;nenese odpovědnost za učinění nabídky zákazníkovi.
              </p>
              <br />
              <p>
                3.5 Zákazník bere na vědomí, že zájezd je kombinací více služeb
                cestovního ruchu, které&nbsp;
                <span id="m_6832594885467667968gmail-highlight21">CK</span>
                &nbsp;poptává u více smluvních partnerů (hotely, přepravní
                společnosti aj.). V&nbsp;případě, že dojde k&nbsp;naplnění
                kapacity některé ze služeb cestovního ruchu, která je součástí
                zájezdu dle katalogu, je&nbsp;
                <span id="m_6832594885467667968gmail-highlight22">CK</span>
                &nbsp;oprávněna tento zájezd dále nenabízet.
              </p>
              <br />
              <p>
                3.6 Zákazník dále bere na vědomí, že informace ohledně volné
                kapacity jednotlivých zájezdů uváděných na internetových
                stránkách, jsou pouze orientační. Aktuální dostupnost
                příslušného zájezdu může zákazníkovi sdělit pouze
                zaměstnanec&nbsp;
                <span id="m_6832594885467667968gmail-highlight23">CK</span>.
              </p>
              <br />
              <p>
                3.7 V&nbsp;případě rezervace zájezdu prováděné prostřednictvím
                internetových stránek&nbsp;
                <span id="m_6832594885467667968gmail-highlight24">CK</span>
                &nbsp;bude ze strany&nbsp;
                <span id="m_6832594885467667968gmail-highlight25">CK</span>
                &nbsp;ověřena dostupnost takového zájezdu, a to nejpozději do 2
                pracovních dní od okamžiku odeslání rezervace. V&nbsp;případě,
                že kapacita poptávaného zájezdu bude volná, vytvoří&nbsp;
                <span id="m_6832594885467667968gmail-highlight26">CK</span>
                &nbsp;zákazníkovi rezervaci. Zákazník nemá právní nárok na
                uzavření smlouvy dle jím učiněné rezervace v&nbsp;případě, že
                bude naplněna kapacita některé ze služeb cestovního ruchu jím
                poptávaného zájezdu. Rozhodný je okamžik ověření dostupnosti ze
                strany&nbsp;
                <span id="m_6832594885467667968gmail-highlight27">CK</span>,
                nikoliv okamžik učinění rezervace.
              </p>
              <br />
              <h2>
                <b>IV. Obsah smlouvy o zájezdu</b>
              </h2>
              <br />
              <p>
                4.1 Smluvní vztah mezi&nbsp;
                <span id="m_6832594885467667968gmail-highlight28">CK</span>
                &nbsp;a zákazníkem vzniká uzavřením smlouvy o zájezdu.
              </p>
              <br />
              <p>
                4.2 Není-li smlouva o zájezdu zavřena v&nbsp;písemné formě, pak
                smluvní vztah vzniká okamžikem odeslání potvrzení o zájezdu ze
                strany&nbsp;
                <span id="m_6832594885467667968gmail-highlight29">CK</span>
                &nbsp;na e-mail, popřípadě korespondenční adresu
                objednatele.&nbsp;
                <span id="m_6832594885467667968gmail-highlight30">CK</span>
                &nbsp;nenese odpovědnost za funkčnost a nastavení e-mailové
                schránky objednatele.
              </p>
              <br />
              <p>
                4.3 Odesláním objednávky, popřípadě podpisem smlouvy o zájezdu
                objednatel potvrzuje, že seznámil všechny zákazníky
                s&nbsp;katalogem, těmito VOP, přepravními podmínkami, jakož i
                veškerými dalšími podmínkami vztahujícími se k&nbsp;příslušnému
                zájezdu. Objednatel rovněž odesláním objednávky, popřípadě
                podpisem smlouvy o zájezdu potvrzuje, že všichni zákazníci se
                všemi těmito podmínkami bezvýhradně souhlasí.
              </p>
              <br />
              <p>
                4.4 Není-li ve smlouvě o zájezdu sjednáno jinak, je rozsah
                zájezdu určen dle katalogu. Zákazník podpisem smlouvy o zájezdu
                prohlašuje, že si před uzavřením smlouvy o zájezdu VOP,
                přepravní podmínky, jakož i zvláštní podmínky prostudoval, všemu
                porozuměl a případné nesrovnalosti mu byly ze strany&nbsp;
                <span id="m_6832594885467667968gmail-highlight31">CK</span>
                &nbsp;vysvětleny.
              </p>
              <br />
              <p>
                4.5 Součástí smlouvy o zájezdu jsou i tyto VOP, přepravní
                podmínky přepravní společnosti, jakož případně i zvláštní
                podmínky dle charakteru konkrétního zájezdu.
              </p>
              <br />
              <p>
                4.6 Není-li nějaká otázka smluvního vztahu mezi zákazníkem
                a&nbsp;
                <span id="m_6832594885467667968gmail-highlight32">CK</span>
                &nbsp;určena ve smlouvě o zájezdu, VOP, přepravních podmínkách
                či zvláštních podmínkách, pak se na tuto otázku použijí
                příslušná ustanovení obecně závazných právních předpisů, zejména
                zákona č. 89/2012 Sb., občanský zákoník, ve znění pozdějších
                předpisů, a dále zákona č. 159/1999 Sb., o některých podmínkách
                podnikání v oblasti cestovního ruchu a o změně zákona č. 40/1964
                Sb., občanský zákoník, ve znění pozdějších předpisů, a zákona č.
                455/1991 Sb., o živnostenském podnikání (živnostenský zákon), ve
                znění pozdějších předpisů.
              </p>
              <br />
              <ol>
                <li>
                  <strong>Mimořádná a protiepidemická opatření</strong>
                  <ul>
                    <li>
                      Zákazník bere na vědomí a souhlasí s tím, že v souvislosti
                      se zavedením protiepidemických či jiných mimořádných
                      opatření může v průběhu zájezdu docházet ke změně v
                      rozsahu či kvalitě sjednaných služeb (např.
                      k&nbsp;omezením souvisejícím s dopravou, k&nbsp;omezením
                      služeb, zejména rozsahu a způsobu poskytování
                      stravovacích, ubytovacích a dalších služeb, uzavření či
                      omezení jednotlivých provozů hotelů, pláže, bazénů,
                      sportovišť, animačních programů, atrakcí atd.). Uvedené
                      odchylky od sjednaných služeb nezakládají právo zákazníka
                      a dalších cestujících, v jejichž prospěch byla tato
                      Smlouva o zájezdu uzavřena, na slevu z&nbsp;ceny zájezdu,
                      náhradu škody, i materiální újmy či právo na odstoupení od
                      Smlouvy o zájezdu bez odstupného (stornopoplatků) dle čl.
                      8 Odstupné.
                    </li>
                    <br />
                    <li>
                      Zákazník bere na vědomí a souhlasí s tím, že v souvislosti
                      se zavedením protiepidemických či jiných mimořádných
                      opatření mohou vlády jednotlivých států či jednotliví
                      dodavatelé služeb (přepravní společnosti, ubytovatelé,
                      letiště apod.) požadovat administrativní úkony před
                      nástupem na zájezd nebo během zájezdu (např. vyplnění
                      vstupních formulářů), antigenní nebo PCR testy, případně
                      jiné požadavky, které budou podmínkou vstupu do
                      jednotlivých států a k poskytnutí služeb od dodavatelů
                      služeb (ubytovatelů, přepravců, letišť atd.). Výše uvedené
                      požadavky neopravňují Zákazníka k odstoupení od Smlouvy o
                      zájezdu bez odstupného (stornopoplatků) dle čl. 8
                      Odstupné.
                    </li>
                  </ul>
                </li>
              </ol>
              <br />
              <h2>
                <b>V. Cena zájezdu</b>
              </h2>
              <br />
              <p>
                5.1 Cena zájezdu je cenou smluvní, která vychází z&nbsp;údajů
                v&nbsp;katalogu. Cena zájezdu obsahuje služby uvedené
                v&nbsp;katalogu. Cena je uvedená včetně daně z&nbsp;přidané
                hodnoty.
              </p>
              <br />
              <p>
                5.2 Cena zájezdu je kalkulována podle kurzu ČNB ke dni
                objednávky.
              </p>
              <br />
              <p>
                5.3 Oznámení o zvýšení ceny zájezdu musí být učiněno písemně a
                odesláno objednateli zájezdu na jím uvedenou adresu pobytu. Toto
                oznámení je zapotřebí objednateli odeslat nejpozději 21. den
                před zahájením zájezdu (poskytnutím první ze služeb cestovního
                ruchu).
              </p>
              <br />
              <p>
                5.4 Cena zájezdu bude zvýšena o částku odpovídající navýšení
                směnného kurzu české koruny, ceny pohonných hmot, popřípadě
                jiného obdobného plnění dle tohoto odstavce. Ve svém oznámení o
                navýšení ceny zájezdu pak&nbsp;
                <span id="m_6832594885467667968gmail-highlight34">CK</span>
                &nbsp;uvede výpočet ceny zájezdu před navýšením ceny,
                skutečnosti odůvodňující navýšení ceny, výpočet nové ceny a její
                konečnou výši.
              </p>
              <br />
              <p>
                5.5 Navrhne-li&nbsp;
                <span id="m_6832594885467667968gmail-highlight35">CK</span>
                &nbsp;zvýšení ceny zájezdu o více než osm procent, může
                objednatel návrh přijmout nebo může odstoupit od smlouvy ve
                lhůtě 7 dní od doručení oznámení o navýšení ceny, aniž by musel
                hradit odstupné za předčasné ukončení závazku. Neodstoupí-li
                zákazník od smlouvy o zájezdu v určené lhůtě, platí, že se
                změnou ceny zájezdu souhlasí. Objednatel je povinen doplatit
                rozdíl v&nbsp;ceně zájezdu do 8 dní od okamžiku doručení
                oznámení o navýšení ceny zájezdu. V&nbsp;případě, že objednatel
                či jakýkoliv jiný zákazník neodstoupí od smlouvy z&nbsp;důvodu
                navýšení ceny ze strany&nbsp;
                <span id="m_6832594885467667968gmail-highlight36">CK</span>, ale
                zároveň nedoplatí rozdíl v&nbsp;ceně do 10 dní od okamžiku
                doručení oznámení o navýšení ceny, může&nbsp;
                <span id="m_6832594885467667968gmail-highlight37">CK</span>
                &nbsp;od smlouvy odstoupit. Zákazník je povinen zaplatit&nbsp;
                <span id="m_6832594885467667968gmail-highlight38">CK</span>
                &nbsp;odstupné, a to ve výši dle těchto VOP.
              </p>
              <br />
              <p>
                5.6 Dojde-li ke snížení některého z&nbsp;faktorů, který má vliv
                na zvýšení ceny zájezdu dle těchto VOP, pak&nbsp;
                <span id="m_6832594885467667968gmail-highlight39">CK</span>
                &nbsp;provede automaticky ponížení ceny zájezdu. Při snížení
                ceny zájezdu, jakož i při vyplácení rozdílu postupuje&nbsp;
                <span id="m_6832594885467667968gmail-highlight40">CK</span>
                &nbsp;obdobně jako při zvyšování ceny zájezdu.
              </p>
              <br />
              <h2>
                <b>VI. Platební podmínky</b>
              </h2>
              <br />
              <p>
                6.1 Objednatel je povinen zaplatit celou výši zájezdu před jeho
                zahájením. Částka se považuje za zaplacenou okamžikem jejího
                zaplacení v&nbsp;hotovosti v&nbsp;sídle či provozovnách&nbsp;
                <span id="m_6832594885467667968gmail-highlight41">CK</span>,
                provozovně provizního prodejce, popřípadě zmocněného obchodního
                zástupce.
              </p>
              <br />
              <p>
                6.2 V&nbsp;případě bezhotovostní platby se považuje částka za
                zaplacenou okamžikem jejího připsání na bankovní účet&nbsp;
                <span id="m_6832594885467667968gmail-highlight42">CK</span>
                &nbsp;uvedený v&nbsp;cestovní smlouvě, popřípadě těchto VOP.
              </p>
              <br />
              <p>
                6.3 V&nbsp;případě, že objednatel nedodrží platební podmínky
                uvedené v&nbsp;těchto VOP, je&nbsp;
                <span id="m_6832594885467667968gmail-highlight43">CK</span>
                &nbsp;oprávněna od smlouvy o zájezdu odstoupit. Objednatel je
                v&nbsp;takovém případě povinen zaplatit&nbsp;
                <span id="m_6832594885467667968gmail-highlight44">CK</span>
                &nbsp;odstupné, a to ve výši a za podmínek definovaných těmito
                VOP.
              </p>
              <br />
              <p>
                6.4 Vyplacením odstupného není dotčeno právo&nbsp;
                <span>CK</span>
                &nbsp;na náhradu škody, a to v&nbsp;plné výši.
              </p>
              <br />
              <p>
                6.5 Není-li ve smlouvě o zájezdu sjednáno jinak, pak je
                objednatel povinen uhradit 50% z&nbsp;celkové ceny zájezdu při
                uzavření smlouvy o zájezdu. Zbylá část ceny zájezdu je splatná
                30 dnů před začátkem zájezdu. V&nbsp;případě, že smlouva o
                zájezdu není uzavřena v&nbsp;písemné formě, pak je cena zájezdu
                splatná do 3 dnů od okamžiku doručení potvrzení o zájezdu.
              </p>
              <br />
              <p>
                6.6 V&nbsp;případě, že je objednatel v&nbsp;prodlení se
                zaplacením byť jen části ceny zájezdu, je&nbsp;
                <span>CK</span>
                &nbsp;oprávněna od uzavřené smlouvy o zájezdu odstoupit.
              </p>
              <br />
              <p>
                6.7 Poplatky za postoupení smlouvy jsou splatné v&nbsp;hotovosti
                ihned.
              </p>
              <br />
              <h2>
                <b>VII. Práva a povinnosti zákazníka</b>
              </h2>
              <br />

              <p>
                7.1 Zákazník má právo: <br />
                a) získat veškeré informace týkající se zájezdu, a to ještě před
                uzavřením smlouvy o zájezdu,
              </p>

              <p>
                b) obdržet služby cestovního ruchu, které jsou součástí zájezdu.
                V&nbsp;případě, že nedojde k&nbsp;vyčerpání všech služeb
                cestovního ruchu z&nbsp;důvodu na straně zákazníka, nemá
                zákazník nárok na jakékoliv plnění ze strany&nbsp;
                <span id="m_6832594885467667968gmail-highlight47">CK</span>,
              </p>

              <p>
                c) obdržet spolu se smlouvou o zájezdu, popřípadě
                s&nbsp;potvrzením o zájezdu doklad o povinném pojištění&nbsp;
                <span id="m_6832594885467667968gmail-highlight48">CK</span>
                &nbsp;pro případ jejího úpadku,
              </p>

              <p>
                d) na ochranu osobních údajů v&nbsp;souladu s&nbsp;příslušnými
                právními předpisy,
              </p>

              <p>
                e) odstoupit od uzavřené smlouvy o zájezdu, a to ve lhůtě a za
                podmínek stanovených ve smlouvě o zájezdu a těchto VOP,
              </p>

              <p>
                f) obdržet informace o všech podstatných skutečnostech
                týkajících se zájezdu (před odjezdem), a to způsobem a za
                podmínek stanovených ve smlouvě o zájezdu a těchto VOP,
              </p>

              <p>g) být seznámen s&nbsp;případnými změnami zájezdu,</p>

              <p>
                h) reklamovat zájezd nebo jednotlivé služby cestovního ruchu, a
                to za podmínek a způsobem uvedeným v&nbsp;těchto VOP.
              </p>
              <br />

              <p>7.2 Zákazník je povinen:</p>
              <p>
                a) zaplatit celou cenu zájezdu, a to ve lhůtě a za podmínek
                stanovených smlouvou o zájezdu a těmito VOP,
              </p>
              <p>
                b) dodržovat podmínky&nbsp;
                <span id="m_6832594885467667968gmail-highlight49">CK</span>,
                přepravní společnosti, jakož i právní předpisy všech států, na
                jejichž území se bude v&nbsp;rámci zájezdu nacházet,
              </p>
              <p>
                c) dbát doporučení&nbsp;
                <span id="m_6832594885467667968gmail-highlight50">CK</span>,
                jakož i Ministerstva zahraničních věcí ČR,
              </p>
              <p>
                d) dodržovat termíny odjezdu/odletu/vyplutí stanovené&nbsp;
                <span id="m_6832594885467667968gmail-highlight51">CK</span>
                &nbsp;popřípadě přepravní společností,
              </p>
              <p>
                e) poskytovat&nbsp;
                <span id="m_6832594885467667968gmail-highlight52">CK</span>
                &nbsp;maximální možnou součinnost,
              </p>
              <p>
                f) sdělovat o sobě, jakož i o ostatních zákaznících úplné a
                pravdivé informace. Zákazník bere tímto na vědomí, že zatajení,
                neuvedení, popřípadě sdělení nesprávního údaje o osobě může vést
                k&nbsp;neposkytnutí služby cestovního ruchu, popřípadě i
                k&nbsp;protiprávnímu jednání posuzovaného dle právního řádu
                státu, na jehož území bude toto jednání zjištěno. Sankce za toto
                porušení bude posuzována výhradně v&nbsp;souladu s&nbsp;právním
                řádem příslušného státu,
              </p>
              <p>
                g) mít u sebe všechny doklady nezbytné pro vstup a pobyt ve
                státech, na jejichž území se bude v&nbsp;rámci zájezdu zdržovat,
                u těchto dokladů je pak zákazník povinen dodržet minimální dobu
                platnosti pro dobu po návratu do ČR dle legislativy příslušného
                státu,
              </p>
              <p>
                h) zajistit dohled nad osobou mladší 18 let (není-li
                v&nbsp;některém státě vyšší věková hranice). Zákazník bere na
                vědomí, že&nbsp;
                <span id="m_6832594885467667968gmail-highlight53">CK</span>
                &nbsp;neodpovídá za zákazníky mladší 18 let,
              </p>
              <p>
                i) převzít od&nbsp;
                <span id="m_6832594885467667968gmail-highlight54">CK</span>
                &nbsp;veškeré dokumenty, popřípadě jiné věci nezbytné pro řádné
                poskytování služeb cestovního ruchu,
              </p>
              <p>
                j) být v&nbsp;době 7 dní před odletem kontaktní na telefonu,
                který uvede ve smlouvě o zájezdu,
              </p>
              <p>
                k) dodržovat právní řád všech zemí, na jejichž území se bude po
                dobu zájezdu nacházet,
              </p>
              <p>
                l) respektovat odlišnou sociální a náboženskou skladbu
                obyvatelstva, dodržovat místní zvyky a obyčeje,
              </p>
              <p>
                m) uhradit veškeré služby, které nejsou součástí zájezdu a které
                si sjednal sám zákazník v&nbsp;místě zájezdu, popřípadě o
                jejichž sjednání požádal&nbsp;
                <span id="m_6832594885467667968gmail-highlight55">CK</span>,
              </p>
              <p>
                n) dodržovat pokyny a doporučení zástupce&nbsp;
                <span id="m_6832594885467667968gmail-highlight56">CK</span>
                &nbsp;v&nbsp;cizí zemi,
              </p>
              <p>
                o) dodržovat pravidla ubytovacího zařízení, a to včetně pravidel
                týkajících se zákazu konzumace alkoholu, omamných a
                psychotropních látek, dodržování nočního klidu atd.,
              </p>
              <p>
                p) uhradit škodu, kterou způsobil v&nbsp;dopravním prostředku,
                ubytování, popřípadě kdekoliv jinde v&nbsp;průběhu zájezdu,
              </p>
              <p>
                q) zdržet se jednání, které by mohlo ohrozit život, zdraví či
                majetek&nbsp;
                <span id="m_6832594885467667968gmail-highlight57">CK</span>,
                ostatních zákazníků či třetích osob,
              </p>
              <p>
                r) případné vady reklamovat bez zbytečného odkladu, tyto vady
                řádně zdokumentovat,
              </p>
              <p>
                s) v&nbsp;případě, že je zákazníkem či objednatelem právnická
                osoba, pak je povinna seznámit se všemi právy a povinnostmi
                vyplývajícími ze smlouvy o zájezdu, VOP, přepravních podmínek,
                zvláštních podmínek, jakož i ostatních dokumentů všechny
                cestující a zajistit jejich dodržování.
              </p>
              <br />

              <h2>
                <b>
                  VIII. Změny a zrušení smluvně sjednaných služeb cestovního
                  ruchu
                </b>
              </h2>
              <br />
              <p>
                8.1&nbsp;
                <span>CK</span>
                &nbsp;si v&nbsp;souladu s&nbsp;ust.&nbsp; § 2531 odst. 1 zákona
                č. 89/2012 Sb., občanského zákoníku, ve znění pozdějších
                předpisů, sjednává právo k provedení nepodstatných změn smlouvy
                o zájezdu.&nbsp;
                <span>CK</span>
                &nbsp;je povinna tyto změny oznámit zákazníkovi jasným a
                srozumitelným způsobem, a to v&nbsp;textové podobě.
                V&nbsp;případě těchto změn nemá zákazník nárok na odstoupení od
                smlouvy, resp. je oprávněn od smlouvy odstoupit za standardních
                storno podmínek, tedy včetně povinnosti zaplatit storno
                poplatek.
              </p>
              <br />
              <p>
                8.2 V&nbsp;případě, že je&nbsp;
                <span id="m_6832594885467667968gmail-highlight60">CK</span>
                &nbsp;nucena učinit podstatnou změnu smlouvy o zájezdu, pak o
                této skutečnosti informuje bez zbytečného odkladu zákazníka a
                požádá jej o jeho písemný souhlas s&nbsp;těmito změnami.
                Zákazník je povinen vyjádřit se k&nbsp;návrhu&nbsp;
                <span id="m_6832594885467667968gmail-highlight61">CK</span>
                &nbsp;ve lhůtě 5 dní od okamžiku doručení příslušného oznámení,
                nejpozději však před zahájením zájezdu. V&nbsp;případě, že
                zákazník s&nbsp;navrhovanými změnami nesouhlasí, je oprávněn od
                sjednané smlouvy o zájezdu odstoupit. Zákazníkovi v&nbsp;takovém
                případě nevzniká povinnost hradit odstupné. V&nbsp;případě, že
                zákazník ve výše uvedené lhůtě od smlouvy neodstoupí, popřípadě
                se nijak nevyjádří, bere se, že s&nbsp;navrhovanými změnami
                souhlasí.
              </p>
              <br />

              <p>8.3 Za podstatnou změnu se považuje změna:</p>
              <p>
                a)&nbsp;místa určení cesty nebo pobytu, trasy a délky pobytu
                včetně termínů a, je-li součástí ubytování, i počtu nocí,
              </p>
              <p>
                b)&nbsp;ujednaného dopravního prostředku, jeho vlastnosti a
                kategorie, místa, data a času odjezdu a příjezdu, trvání a místa
                zastávek a dopravního spojení; není-li přesný čas ještě
                stanoven, sdělí pořadatel a tam, kde je to významné,
                zprostředkovatel prodeje zájezdu zákazníkovi přibližný čas
                odjezdu a příjezdu,
              </p>
              <p>
                c)&nbsp;umístění, hlavních znaků a případné turistické
                kategorie, do níž je podle pravidel státu místa určení cesty
                nebo pobytu ubytování zařazeno,
              </p>
              <p>d)&nbsp;ujednaného stravování,</p>
              <p>
                e)&nbsp;návštěv, výletů nebo jiné služby zahrnuté v celkové ceně
                zájezdu,
              </p>
              <p>
                f)&nbsp;údaje, zda některá ze služeb cestovního ruchu bude
                zákazníkovi poskytována v rámci skupiny a, je-li tomu tak a
                je-li to možné, o přibližné velikosti skupiny, pokud to není
                zřejmé ze souvislostí,
              </p>
              <p>
                g)&nbsp;jazyku, v němž se služby cestovního ruchu poskytují,
                závisí-li využití těchto služeb zákazníkem na ústní komunikaci,
              </p>
              <p>
                h)&nbsp;údaje, zda jsou cesta nebo pobyt obecně vhodné pro osoby
                s omezenou schopností pohybu, popřípadě údaje o vhodnosti cesty
                nebo pobytu s ohledem na potřeby zákazníka,
              </p>
              <p>
                i)&nbsp;celkové ceny zájezdu včetně daní, poplatků nebo jiných
                obdobných peněžitých plnění a veškerých případných dalších
                nákladů,
              </p>
              <p>
                j)&nbsp;způsobu platby včetně částky nebo procenta z ceny, jež
                mají být zaplaceny jako záloha, a časový rozvrh pro zaplacení
                zbývající části ceny, nebo peněžité jistoty, které musí zákazník
                uhradit nebo poskytnout,
              </p>
              <p>
                k)&nbsp;nejnižšího počtu osob nutného k uskutečnění zájezdu a
                lhůty, během níž může pořadatel odstoupit od smlouvy podle&nbsp;
                § 2536 odst. 1 písm. a) zákona č. 89/2012 Sb., občanského
                zákoníku,
              </p>
              <p>
                l)&nbsp;údaje, že zákazník může odstoupit od smlouvy kdykoli
                před zahájením zájezdu proti zaplacení přiměřeného odstupného za
                předčasné ukončení závazku ze smlouvy, případně odstupného
                stanoveného pořadatelem v souladu se zákonem upravujícím některé
                podmínky podnikání a výkon některých činností v oblasti
                cestovního ruchu.
              </p>
              <br />

              <p>
                8.4&nbsp;
                <span>CK</span>
                &nbsp;oznámí zákazníkovi společně s předložením návrhu na změnu
                závazku jasným, srozumitelným a zřetelným způsobem a bez
                zbytečného odkladu níže uvedené informace (tyto musejí být
                opětovně zaznamenané v textové podobě):
              </p>
              <p>a)&nbsp;dopad navrhovaných změn na cenu zájezdu,</p>
              <p>b)&nbsp;lhůtu, v níž může zákazník odstoupit od smlouvy,</p>
              <p>
                c)&nbsp;důsledky pro zákazníka, neodstoupí-li včas od smlouvy,
              </p>
              <p>d)&nbsp;údaje o případném náhradním zájezdu a jeho ceně.</p>
              <br />

              <p>
                8.5&nbsp;
                <span id="m_6832594885467667968gmail-highlight63">CK</span>
                &nbsp;může odstoupit od smlouvy o zájezdu, pokud:
              </p>
              <p>
                a)&nbsp;počet osob přihlášených na zájezd je nižší než nejnižší
                počet určený ve smlouvě.&nbsp;
                <span id="m_6832594885467667968gmail-highlight64">CK</span>
                &nbsp;je v&nbsp;tomto případě povinna oznámit zákazníkovi
                zrušení zájezdu ve lhůtě:
              </p>
              <ul>
                <li>
                  <p>
                    dvacet dní před zahájením zájezdu v případě cest trvajících
                    déle než šest dní,
                  </p>
                </li>
                <li>
                  <p>
                    sedm dní před zahájením zájezdu v případě cest trvajících
                    dva až šest dní,
                  </p>
                </li>
                <li>
                  <p>
                    čtyřicet osm hodin před zahájením zájezdu v případě cest
                    trvajících méně než dva dny, nebo
                  </p>
                </li>
              </ul>
              <p>
                b)&nbsp;jí v plnění závazku brání nevyhnutelné a mimořádné
                okolnosti a zrušení zájezdu oznámí zákazníkovi bez zbytečného
                odkladu ještě před zahájením zájezdu.
              </p>
              <br />

              <p>
                8.6 Informace dle tohoto odstavce budou zákazníkovi doručeny na
                e-mail nebo adresu uvedenou ve smlouvě o zájezdu, a to bez
                zbytečného odkladu poté, co&nbsp;
                <span id="m_6832594885467667968gmail-highlight65">CK</span>
                &nbsp;zjistí potřebu provedení příslušné změny.
              </p>
              <br />

              <h2>
                <b>IX. Postoupení smlouvy o zájezdu</b>
              </h2>
              <br />

              <p>
                9.1 Zákazník může před zahájením zájezdu oznámit písemně&nbsp;
                <span id="m_6832594885467667968gmail-highlight66">CK</span>, že
                se namísto něj zúčastní zájezdu jiná osoba pouze v&nbsp;případě,
                že nový zákazník splňuje podmínky pro účast na zájezdu.
              </p>
              <br />
              <p>
                9.2 Součástí toho oznámení musí být vyjma identifikace nového
                zákazníka (v rozsahu minimálně jako je identifikován původní
                zákazník ve smlouvě o zájezdu) prohlášení nového zákazníka, že
                souhlasí se smlouvou o zájezdu, VOP, přepravními podmínkami,
                jakož i dalšími podmínkami zájezdu.
              </p>
              <br />
              <p>
                9.3 Oznámení musí být&nbsp;
                <span id="m_6832594885467667968gmail-highlight67">CK</span>
                &nbsp;doručeno alespoň 7 dní před zahájením zájezdu.
              </p>
              <br />
              <p>
                9.4&nbsp;
                <span id="m_6832594885467667968gmail-highlight68">CK</span>
                &nbsp;poté bez zbytečného odkladu sdělí novému i původnímu
                zákazníkovi náklady spojené s&nbsp;postoupením smlouvy o
                zájezdu. Tyto náklady nesmějí být nepřiměřené a nesmějí být
                vyšší než náklady skutečně vynaložené na postoupení smlouvy.
                Náklady na postoupení smlouvy o zájezdu jsou povinni uhradit
                nový a původní zákazník společně a nerozdílně.
              </p>
              <br />
              <p>
                9.5 Zákazník je povinen oznámit&nbsp;
                <span id="m_6832594885467667968gmail-highlight69">CK</span>
                &nbsp;postoupení zájezdu včas, a to zejména s&nbsp;ohledem na
                dobu potřebnou pro zajištění souvisejících služeb (např. víza).
                Za včasnost oznámení o postoupení zájezdu odpovídá zákazník.
              </p>
              <br />
              <p>
                9.6&nbsp;
                <span id="m_6832594885467667968gmail-highlight70">CK</span>
                &nbsp;je povinna oznámit zákazníkovi, na základě jeho žádosti,
                dobu potřebnou pro učinění nezbytných úkonů spojených
                s&nbsp;postoupením smlouvy, aby se mohl nový zákazník zájezdu
                zúčastnit.
              </p>
              <br />
              <p>
                9.7 V&nbsp;případě, že nový zákazník nebude splňovat podmínky
                pro přiznání slevy či jiné výhody, na níž měl nárok původní
                zákazník (např. sleva za včasný nákup, dítě zdarma, opakovaná
                účast, stálý zákazník apod), pak jsou původní a nový zákazník
                povinni společně a nerozdílně zaplatit&nbsp;
                <span id="m_6832594885467667968gmail-highlight71">CK</span>
                &nbsp;rozdíl v&nbsp;ceně zájezdu, a to společně s&nbsp;náklady
                na postoupení zájezdu.
              </p>
              <br />
              <h2>
                <b>X. Odstoupení od smlouvy o zájezdu</b>
              </h2>
              <br />
              <p>
                10.1 Odstoupení ze strany&nbsp;
                <span>CK</span>
              </p>
              <p>
                <span id="m_6832594885467667968gmail-highlight73">CK</span>
                &nbsp;může odstoupit od smlouvy o zájezdu odstoupit, pokud:
              </p>
              <p>
                a)&nbsp;počet osob přihlášených na zájezd je nižší než nejnižší
                počet&nbsp;
                <b>určený ve smlouvě</b>
                .&nbsp;
                <span id="m_6832594885467667968gmail-highlight74">Ck</span>
                &nbsp;je povinna oznámit zákazníkovi zrušení zájezdu ve lhůtě:
              </p>
              <ul>
                <li>
                  <p>
                    dvacet dní před zahájením zájezdu v případě cest trvajících
                    déle než šest dní,
                  </p>
                </li>
                <li>
                  <p>
                    sedm dní před zahájením zájezdu v případě cest trvajících
                    dva až šest dní,
                  </p>
                </li>
                <li>
                  <p>
                    čtyřicet osm hodin před zahájením zájezdu v případě cest
                    trvajících méně než dva dny, nebo
                  </p>
                </li>
              </ul>
              <p>
                b)&nbsp;jí v plnění závazku brání nevyhnutelné a mimořádné
                okolnosti. Zrušení zájezdu je v&nbsp;tomto případě&nbsp;
                <span id="m_6832594885467667968gmail-highlight75">CK</span>
                &nbsp;povinna oznámit zákazníkovi bez zbytečného odkladu ještě
                před zahájením zájezdu.
              </p>
              <p>
                <span id="m_6832594885467667968gmail-highlight76">CK</span>
                &nbsp;v těchto případech vrátí zákazníkovi do 14 dní od okamžiku
                odstoupení veškeré uhrazené platby za zájezd, nevzniká jí však
                vůči zákazníkovi povinnost k náhradě škody.
              </p>
              <br />

              <h2>
                <b>XI. Reklamace zájezdu</b>
              </h2>
              <br />
              <p>
                11.1 Zákazník je oprávněn reklamovat jakékoliv vady zájezdu, tj.
                služby cestovního ruchu, které nebyly poskytnuty vůbec,
                popřípadě v&nbsp;jiné kvalitě či množství, než jak bylo sjednáno
                ve smlouvě o zájezdu.
              </p>
              <br />
              <p>
                11.2 Zákazník je povinen reklamovat vady bez zbytečného odkladu
                po jejich zjištění. Zákazník je povinen o zjištěných vadách
                informovat zástupce&nbsp;
                <span>CK</span>, tj. zejména průvodce nebo delegáta a určit, zda
                si přeje vadu odstranit či zda žádá přiměřenou slevu u ceny
                zájezdu. Tato volba je pro&nbsp;
                <span>CK</span>
                &nbsp;závaznou. V&nbsp;případě žádosti o odstranění vady určí
                zákazník též přiměřenou lhůtu pro odstranění vady, ledaže je
                vada takového charakteru, že je nutné ji odstranit neprodleně.
              </p>
              <br />
              <p>
                11.3 Písemné reklamace je zákazník povinen doručit na adresu
                sídla cestovní kanceláře.
              </p>
              <br />
              <p>
                11.4 V&nbsp;případě, že se vyskytnou vady po zahájení zájezdu,
                je&nbsp;
                <span>CK</span>
                &nbsp;povinna vyvinout maximální možné úsilí k&nbsp;zajištění
                ekvivalentní služby cestovního ruchu ve stejné nebo vyšší
                kvalitě. V&nbsp;případě, že toto není z&nbsp;objektivních příčin
                možné, poskytne&nbsp;
                <span>CK</span>
                &nbsp;zákazníkovi službu cestovního ruchu v&nbsp;nižší než
                sjednané kvalitě a zároveň mu poskytne přiměřenou slevu
                z&nbsp;ceny zájezdu.
              </p>
              <br />
              <p>
                11.5 V&nbsp;případě, že&nbsp;
                <span>CK</span>
                &nbsp;vadu neodstraní ani nenabídne zákazníkovi alternativní
                službu cestovního ruchu stejné nebo vyšší kvality, je zákazník
                oprávněn odstranit vadu zájezdu sám, a to na náklady&nbsp;
                <span>CK</span>.
              </p>
              <br />
              <h2>
                <b>XII. Odpovědnost za škody</b>
              </h2>
              <p>
                12.1&nbsp;
                <span>CK</span>
                &nbsp;odpovídá pouze za škody vzniklé zákazníkovi
                v&nbsp;souvislosti se službami cestovního ruchu poskytovanými na
                základě smlouvy o zájezdu.
              </p>
              <br />
              <p>
                12.2 Spoluzavinění zákazníka může mít za následek krácení nároku
                na náhradu škody, popřípadě vyloučení tohoto nároku v&nbsp;plné
                výši.
              </p>
              <br />
              <p>
                12.3&nbsp;
                <span>CK</span>
                &nbsp;však neodpovídá za škody vzniklé z&nbsp;důvodu zásahu
                vyšší moci.
              </p>
              <br />
              <p>
                12.4&nbsp;
                <span>CK</span>
                &nbsp;neodpovídá za jakoukoliv újmu vzniklou zákazníkovi
                v&nbsp;souvislosti s&nbsp;jinými službami než těmi, které byly
                sjednány v&nbsp;rámci smlouvy o zájezdu. V&nbsp;případě, že jiné
                služby ovlivní možnost poskytnutí služby cestovního ruchu dle
                smlouvy o zájezdu, popřípadě zcela znemožní poskytnutí takové
                služby, není za toto&nbsp;
                <span>CK</span>
                &nbsp;odpovědná.
              </p>
              <br />
              <p>
                12.5 Práva a povinnosti cestujících, jakož i povinnosti při
                přepravě zavazadel jsou upraveny v&nbsp;přepravním řádu
                příslušné přepravní společnosti. Těmi podmínkami je zákazník
                povinen se řídit. Případné poškození zavazadel, popřípadě jiné
                vady přepravy doporučuje&nbsp;
                <span>CK</span>
                &nbsp;reklamovat přímo na příletovém letišti.
              </p>
              <br />
              <h2>
                <b>
                  XIII. Pojištění pro případ úpadku&nbsp;
                  <span>CK</span>
                </b>
              </h2>
              <br />
              <p>
                13.1&nbsp;
                <span>CK</span>
                &nbsp;je ve smyslu stanovení&nbsp; § 6 odst. 1 zákona č.
                159/1999 Sb., pojištěna pro případ úpadku, na základě kterého
                vzniká zákazníkovi&nbsp;
                <span>CK</span>
                &nbsp;právo na plnění v případech, kdy&nbsp;
                <span>CK</span>
                &nbsp;z důvodu svého úpadku:
              </p>
              <p>a)&nbsp;neposkytne zákazníkovi repatriaci,</p>
              <p>
                b)&nbsp;nevrátí zákazníkovi zaplacenou zálohu nebo cenu zájezdu
                včetně ceny zaplacené za poukaz na zájezd v případě
                neuskutečnění zájezdu, nebo
              </p>
              <p>
                c)&nbsp;nevrátí zákazníkovi rozdíl mezi zaplacenou cenou zájezdu
                a cenou částečně poskytnutého zájezdu v případě, že se zájezd
                uskutečnil pouze zčásti.
              </p>
              <br />
              <p>
                13.2&nbsp;
                <span>CK</span>
                &nbsp;je povinna předat zákazníkovi spolu s potvrzením o zájezdu
                nebo se stejnopisem smlouvy o zájezdu doklad, který musí
                obsahovat informace o uzavřeném pojištění, zejména označení
                pojišťovny, podmínky pojištění a způsob oznámení pojistné
                události.
              </p>
              <br />
              <h2>
                <b>XIV. Pojištění zákazníka</b>
              </h2>
              <br />
              <p>
                14.1 Součástí ceny zájezdu není pojištění léčebných výloh,
                pojištění storna zájezdu, pojištění odpovědnosti za škodu,
                připojištění zavazadel či jakékoliv jiné pojištění.
              </p>
              <br />
              <p>
                14.2 Zákazník bere tímto na vědomí, že na základě Evropského
                průkazu zdravotního pojištění nemusí dojít k&nbsp;úhradě všech
                nákladů spojených s&nbsp;poskytnutím zdravotních služeb
                v&nbsp;cizině. Zákazník je povinen zjistit u své zdravotní
                pojišťovny rozsah krytí.
              </p>
              <br />
              <p>
                14.3 V&nbsp;případě nesjednání vhodného pojištění či
                připojištění je zákazník srozuměn s&nbsp;tím, že veškeré náklady
                bude hradit výlučně z&nbsp;vlastních zdrojů.
              </p>
              <br />
              <p>
                14.4&nbsp;
                <span>CK</span>
                &nbsp;nabízí zákazníkům sjednání pojištění léčebných výloh,
                storna zájezdu, odpovědnosti za škodu. Práva a povinnosti při
                sjednávání tohoto pojištění jakož i při čerpání pojistného
                plnění se řídí všeobecnými obchodními podmínkami příslušné
                pojišťovny.
              </p>
              <br />
              <h2>
                <b>
                  XV. Obecné informace o některých službách cestovního ruchu
                </b>
              </h2>
              <br />
              <p>
                15.1&nbsp; Fakultativní výlety: &nbsp;
                <span>CK</span>
                &nbsp;zprostředkovává zákazníkům prostřednictví svých
                průvodců/delegátů fakultativní výlety pořádané místním
                organizátorem. O ceně, programu, délce trvání, jakož i dalších
                podmínkách účasti na fakultativním výletu bude zákazníky
                informovat delegát/průvodce.&nbsp;
                <span>CK</span>
                &nbsp;za průběh fakultativních výletů neodpovídá.
              </p>
              <br />
              <p>
                15.2&nbsp; Kvalita hotelu: &nbsp;Kvalita a rozsah ubytovacích
                služeb je pro každé ubytovací zařízení popsána v&nbsp;katalogu.
                Úroveň hotelu je kvalifikována podle pravidel a v&nbsp;souladu
                s&nbsp;legislativou příslušného státu. Tyto podmínky se mohou
                v&nbsp;jednotlivých státech lišit. Kvalita, jakož i rychlost
                poskytovaných služeb, je rovněž odlišná, kdy zákazníci musejí
                být srozuměni s&nbsp;jistou mírou ležérnosti personálu zvláště
                v&nbsp;tzv. jižních státech.
              </p>
              <br />
              <p>
                15.3 V&nbsp;případě, že není u konkrétního ubytovacího zařízení
                uvedeno jinak, není součástí ceny zájezdu lukrativní výhled
                z&nbsp;pokoje ani balkon. Součástí základní ceny zájezdu (bez
                příplatku) je pak zpravidla výhled do vnitrozemí. Zákazníci
                berou na vědomí, že tento výhled může být na technické zázemí
                ubytovacího zařízení, místní infrastrukturu, směrem
                k&nbsp;jinému objektu apod.
              </p>
            </div>
          </p>
        </div>
      </div>
    </section>
  );
}

export default Terms;
