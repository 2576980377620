import React, { useEffect, useState } from "react";
import { RichText } from "prismic-reactjs";

// import { BackButton, SliceZone } from "../components";
import NotFound from "containers/NotFound";
import { client } from "config/prismic";

const Post = ({ match }: any) => {
  const uid = match.params.uid;
  const [prismicDoc, setPrismicDoc] = useState(
    JSON.parse(window.localStorage.getItem(`TravelKlaudiaBlog${uid}`) as any) ||
      null
  );
  const [notFound, toggleNotFound] = useState(false);

  // Get the blog post document from Prismic
  useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        // @ts-ignore
        const doc = await client.getByUID("post", uid);

        if (doc) {
          window.localStorage.setItem(
            `TravelKlaudiaBlog${uid}`,
            JSON.stringify(doc)
          );
          setPrismicDoc(doc);
        } else {
          console.warn(
            "Blog post document was not found. Make sure it exists in your Prismic repository"
          );
          toggleNotFound(true);
        }
      } catch (error) {
        console.error(error);
        toggleNotFound(true);
      }
    };

    fetchPrismicData();
  }, [uid]);

  // Return the page if a document was retrieved from Prismic
  if (prismicDoc) {
    const title =
      // @ts-ignore
      prismicDoc.data.title.length !== 0
        ? // @ts-ignore
          RichText.asText(prismicDoc.data.title)
        : "Untitled";

    return (
      // @ts-ignore
      <>
        <div className="outer-container">
          {/* <BackButton /> */}
          <h1>{title}</h1>
        </div>
        {/* <SliceZone sliceZone={prismicDoc.data.body} /> */}
      </>
    );
  } else if (notFound) {
    return <NotFound />;
  }
  return null;
};

export default Post;
