import Prismic from '@prismicio/client'
// -- Prismic API endpoint
// Determines which repository to query and fetch data from
// Configure your site's access point here
export const apiEndpoint = 'https://travelklaudia.cdn.prismic.io/api/v2';

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
export const accessToken = 'MC5ZS2xDRHhNQUFDQUFESjVI.QSbvv73vv71N77-977-977-9BXrvv73vv73vv71leu-_vW5NGVsUfjPvv73vv73vv71277-9f--_vT4E';

// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolver = (doc: { type: string; uid: any }) => {
  if (doc.type === 'post') return `/blog/${doc.uid}`
  return '/'
};

export const client = Prismic.client(apiEndpoint, { accessToken })
