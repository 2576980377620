function Protection() {
  return (
    <section className="text-gray-600 body-font relative">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-12">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
            Zásady ochrany osobních údajů
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-left">
            <b>A. Úvod</b>
            <br />
            <br />
            Soukromí návštěvníků našich webových stránek je pro nás velmi
            důležité a zavázali jsme se je chránit. Tato pravidla vysvětlují, co
            s vašimi osobními údaji děláme. Váš souhlas s používáním souborů
            cookies v souladu s našimi podmínkami při první návštěvě našich
            webových stránek nám umožňuje použít soubory cookies při každé vaší
            návštěvě našeho webu.
            <br />
            <br />
            <b>B. Poděkování</b>
            <br />
            <br />
            Tento dokument byl vytvořen za použití šablony z SEQ Legal
            (seqlegal.com) a upraven Website Planet (www.websiteplanet.com).
            <br />
            <br />
            <b>C. Shromažďování osobních údajů</b>
            <br />
            <br />
            Shromažďovány, ukládány a používány mohou být následující typy
            osobních údajů:
            <br />
            <br />
            informace o vašem počítači včetně IP adresy, geografického umístění,
            typu a verze prohlížeče a operačního systému; informace o vašich
            návštěvách a použití těchto webových stránek včetně zdroje
            doporučení, délky návštěvy, zobrazených stránek a průchodu webových
            stránek; informace jako je e-mailová adresa, kterou zadáváte při
            registraci na našich webových stránkách; informace, které zadáte,
            když si na našich webových stránkách vytváříte profil – např. vaše
            jméno, profilové obrázky, pohlaví, datum narození, stav, zájmy a
            koníčky, vzdělání a zaměstnání; informace jako je vaše jméno a
            e-mailová adresa, kterou zadáváte, když se přihlašujete k našim
            e-mailovým službám a/nebo newsletterům; informace, které zadáváte
            při používání služeb na našich webových stránkách; informace
            vygenerované při používání našich webových stránek, včetně toho kdy,
            jak často a za jakých podmínek je používáte; informace související s
            vaším nákupem, použitými službami nebo transakcemi, které přes naše
            webové stránky provedete a které obsahují vaše jméno, adresu,
            telefonní číslo, e-mailovou adresu a podrobnosti o vaší kreditní
            kartě; informace, které odešlete na naše webové stránky s úmyslem
            publikovat je na internetu, včetně vašeho uživatelského jména,
            profilových obrázků a obsahu vašeho příspěvku; informace obsažené v
            jakékoliv komunikaci, kterou nám pošlete e-mailem nebo přes webové
            stránky, včetně samotného obsahu a metadat; jakékoliv další osobní
            údaje, které nám pošlete. Než nám sdělíte osobní údaje jiné osoby,
            musíte získat souhlas této osoby se zveřejněním i zpracováním těchto
            osobních údajů v souladu s těmito zásadami.
            <br />
            <br />
            <b>D. Použití vašich osobních údajů</b>
            <br />
            <br />
            Osobní údaje, které nám byly poskytnuty prostřednictvím našich
            webových stránek, budou použity pro účely uvedené v těchto zásadách
            nebo na příslušných stránkách webových stránek. Vaše osobní údaje
            můžeme využít pro následující účely:
            <br />
            <br />
            správu našich webových stránek a podnikání; přizpůsobení našich
            webových stránek právě vám; umožnění využívání služeb dostupných na
            našich webových stránkách; zasílání zboží zakoupeného
            prostřednictvím našich webových stránek; poskytování služeb
            zakoupených prostřednictvím našich webových stránek; zasílání
            výpisů, faktur, připomínek plateb a vybírání plateb; zasílání
            nekomerčních obchodních sdělení; zasílání konkrétně vyžádaných
            e-mailových oznámení; zaslání našeho e-mailového newsletteru, pokud
            jste o něj požádali (můžete nás kdykoli informovat, pokud jej již
            nevyžadujete); zasílání marketingových sdělení týkajících se našeho
            podnikání nebo podnikání pečlivě vybraných třetích stran, o kterých
            si myslíme, že by vás mohly zajímat, a to poštou nebo, pokud jste s
            tím výslovně souhlasili, e-mailem nebo podobnou technologií (můžete
            nás kdykoli informovat, pokud marketingové sdělení již
            nevyžadujete); poskytování statistických informací o našich
            uživatelích třetím stranám (tyto třetí strany však z těchto
            informací nebudou moci identifikovat žádného jednotlivého
            uživatele); vyřizování dotazů a stížností vámi vznesených nebo jinak
            se vás týkajících v souvislosti s naším webem; udržování bezpečnosti
            našich webových stránek a předcházení podvodům; ověření souladu s
            podmínkami používání našich webových stránek (včetně sledování
            soukromých zpráv zasílaných prostřednictvím naší služby soukromých
            zpráv); a další použití. Pokud odešlete osobní údaje ke zveřejnění
            na našem webu, zveřejníme a použijeme je v souladu s licencí, kterou
            nám poskytnete.
            <br />
            <br />
            Vaše nastavení ochrany osobních údajů lze použít k omezení
            zveřejnění vašich údajů na našem webu a lze je upravit pomocí
            ovládacích prvků ochrany soukromí na tomto webu.
            <br />
            <br />
            Bez vašeho výslovného souhlasu neposkytneme vaše osobní údaje žádné
            třetí straně za účelem přímého marketingu jejich nebo jiné třetí
            strany.
            <br />
            <br />
            <b>E. Zveřejňování osobních údajů</b>
            <br />
            <br />
            Vaše osobní údaje můžeme sdělit kterémukoli z našich zaměstnanců,
            úředníkům, pojišťovnám, profesionálním poradcům, agentům,
            dodavatelům nebo subdodavatelům tak, jak je to přiměřeně nutné pro
            účely stanovené v těchto zásadách.
            <br />
            <br />
            Vaše osobní údaje můžeme sdělit kterémukoli členu naší skupiny
            společností (tzn. našim dceřiným společnostem, naší hlavní
            holdingové společnosti a všem jejím dceřiným společnostem) tak, jak
            je to přiměřeně nutné pro účely stanovené v těchto zásadách.
            <br />
            <br />
            Vaše osobní údaje můžeme zveřejnit:
            <br />
            <br />
            v rozsahu, v jakém jsme povinni tak učinit ze zákona; v souvislosti
            s jakýmkoli probíhajícím nebo budoucím soudním řízením; za účelem
            stanovení, vykonání nebo obrany našich zákonných práv (včetně
            poskytování informací jiným za účelem předcházení podvodům a snížení
            úvěrového rizika); kupujícímu (nebo budoucímu kupujícímu) jakéhokoli
            podnikání nebo aktiv, které prodáváme (nebo o prodeji uvažujeme); a
            jakékoliv osobě, o které se důvodně domníváme, že může požádat soud
            nebo jiný příslušný orgán o poskytnutí těchto osobních údajů a pokud
            by podle našeho názoru mohl takový soud nebo orgán zveřejnění těchto
            osobních údajů nařídit. S výjimkou případů uvedených v těchto
            zásadách nebudeme vaše osobní údaje třetím stranám poskytovat.
            <br />
            <br />
            <b>F. Mezinárodní přenosy dat</b>
            <br />
            <br />
            Údaje, které shromažďujeme, mohou být uchovávány, zpracovávány a
            přenášeny mezi kteroukoli ze zemí, v nichž působíme, abychom je
            mohli používat v souladu s těmito zásadami. Údaje, které
            shromažďujeme, mohou být předány do následujících zemí, které nemají
            zákony na ochranu údajů rovnocenné zákonům platným v Evropském
            hospodářském prostoru: Spojené státy americké, Rusko, Japonsko, Čína
            a Indie. Osobní údaje, které zveřejníte na našem webu nebo odešlete
            ke zveřejnění na našem webu, mohou být prostřednictvím internetu
            dostupné po celém světě. Nemůžeme zabránit použití nebo zneužití
            těchto informací ostatními. Výslovně souhlasíte s převody osobních
            údajů popsanými v této části F.
            <br />
            <br />
            <b>G. Uchovávání osobních údajů</b>
            <br />
            <br />
            V této části G jsou stanoveny zásady a postupy uchovávání údajů,
            jejichž cílem je zajistit, abychom dodržovali naše zákonné
            povinnosti týkající se uchovávání a mazání osobních údajů. Osobní
            údaje, které zpracováváme pro jakýkoli účel nebo účely, se nesmějí
            uchovávat déle, než je pro tento účel nebo účely nezbytné. Aniž je
            dotčen článek G 2, mažeme osobní údaje, které spadají do níže
            uvedených kategorií, k datu a času uvedenému níže: osobní údaje
            budou smazány k 30.1. Bez ohledu na ostatní ustanovení této části G
            si uchováme dokumenty (včetně těch elektronických) obsahující osobní
            údaje v rozsahu, v jakém jsme povinni tak učinit ze zákona; pokud se
            domníváme, že dokumenty mohou být relevantní pro jakékoli
            probíhající nebo budoucí soudní řízení; a za účelem stanovení,
            vykonání nebo obrany našich zákonných práv (včetně poskytování
            informací jiným za účelem předcházení podvodům a snížení úvěrového
            rizika).
            <br />
            <br />
            <b>H. Bezpečnost vašich osobních údajů</b>
            <br />
            <br />
            Přijmeme přiměřená technická a organizační opatření, abychom
            zabránili ztrátě, zneužití nebo změně vašich osobních údajů. Všechny
            vámi poskytnuté osobní údaje uložíme na naše zabezpečené servery
            (chráněné heslem a bránou firewall). Všechny elektronické finanční
            transakce zadané přes naše webové stránky budou zašifrovány. Berete
            na vědomí, že přenos informací přes internet je ze své podstaty
            nejistý a bezpečnost dat odesílaných přes internet nemůžeme zaručit.
            Jste zodpovědní za zachování důvěrnosti hesla, které používáte pro
            přístup na náš web; nebudeme vás žádat o heslo (s výjimkou případů,
            kdy se přihlašujete na náš web). I. Změny
            <br />
            <br />
            Tyto zásady můžeme občas aktualizovat zveřejněním nové verze na
            našich webových stránkách. Tuto stránku byste měli příležitostně
            zkontrolovat, abyste se ujistili, že rozumíte všem změnám v těchto
            zásadách. Na změny v těchto zásadách vás můžeme upozornit e-mailem
            nebo prostřednictvím soukromých zpráv na našich webových stránkách.
            <br />
            <br />
            <b>J. Vaše práva</b>
            <br />
            <br />
            Můžete nám nařídit, abychom vám poskytli jakékoli osobní údaje,
            které o vás máme. Poskytování takovýchto informací bude podléhat
            následujícímu:
            <br />
            <br />
            zaplacení poplatku 500 Kč a předložení příslušného dokladu o vaší
            totožnosti (za tímto účelem obvykle přijímáme fotokopii vašeho pasu
            ověřenou notářem a původní kopii vyúčtování služeb, kde je uvedena
            vaše současná adresa). V rozsahu povoleném zákonem můžeme poskytnutí
            osobních údajů, které požadujete, odmítnout.
            <br />
            <br />
            Můžete nám kdykoliv nařídit, abychom vaše osobní údaje
            nezpracovávali pro marketingové účely.
            <br />
            <br />
            V praxi buď obvykle výslovně předem souhlasíte s tím, jak vaše
            osobní údaje pro marketingové účely použijeme, nebo vám nabídneme
            možnost odhlášení z používání vašich osobních údajů pro marketingové
            účely.
            <br />
            <br />
            <b>K. Webové stránky třetích stran</b>
            <br />
            <br />
            Naše webové stránky obsahují hypertextové odkazy na webové stránky
            třetích stran a podrobnosti o nich. Nad zásadami ochrany osobních
            údajů a postupy třetích stran nemáme kontrolu a neneseme za ně
            odpovědnost.
            <br />
            <br />
            <b>L. Aktualizace údajů</b>
            <br />
            <br />
            Dejte nám prosím vědět, pokud je třeba vaše osobní údaje, které
            máme, opravit nebo aktualizovat.
            <br />
            <br />
            <b>M. Soubory cookies</b>
            <br />
            <br />
            Naše webové stránky používají soubory cookies. Soubor cookie je
            soubor obsahující identifikátor (řetězec písmen a čísel), který je
            webovým serverem odeslán webovému prohlížeči a webovým prohlížečem
            uložen. Tento identifikátor je poté odeslán zpět serveru vždy, když
            si prohlížeč od serveru vyžádá zobrazení stránky. Soubory cookies
            mohou být buď „trvalé“ nebo „relační“. Trvalé soubory cookies budou
            uloženy webovým prohlížečem a zůstávají v platnosti až do data
            jejich vypršení, pokud před datem vypršení nejsou smazány
            uživatelem. Relační soubory cookies naopak vyprší na konci relace
            uživatele po zavření webového prohlížeče. Soubory cookies běžně
            neobsahují žádné informace, které by uživatele jednoznačně
            identifikovaly, ale osobní údaje, které o vás ukládáme, mohou být s
            informacemi uloženými a získanými ze souborů cookies propojeny. Na
            našich webových stránkách používáme jen relační soubory cookies /
            jen trvalé soubory cookies / jak relační tak trvalé soubory cookies.
            <br />
            <br />
            Jména souborů cookies, které používáme na našich webových stránkách,
            a důvody jejich použití, jsou uvedeny níže: <br />
            Na naší webové stránce používáme Google Analytics a Adwords k
            rozpoznání počítače a když uživatel navštíví webovou stránku /
            sledování pohybu uživatele na našich webových stránkách / umožnění
            používání nákupního košíku na webových stránkách / zlepšení
            použitelnosti webových stránek / analýze použití webových stránek /
            správě webových stránek / zabránění podvodům a zlepšení bezpečnosti
            webových stránek / přizpůsobení webových stránek pro každého
            uživatele / cílené reklamě, dle individuálních zájmů konkrétních
            uživatelů / popište důvod(y); Většina prohlížečů umožňuje odmítnout
            přijímání souborů cookies – například: v Internet Exploreru (verze
            10) můžete blokovat soubory cookies pomocí změny nastavení
            zpracování souborů cookies, které je k dispozici kliknutím na
            „Nástroje“, „Možnosti Internetu“, „Soukromí“ a poté „Upřesnit“;
            <br />
            ve Firefoxu (Verze 24) můžete blokovat všechny soubory cookies
            kliknutím na „Nástroje“, „Možnosti“, „Soukromí“, pak v rozbalovací
            nabídce vyberte „Použít vlastní nastavení historie“ a zrušte
            zaškrtnutí políčka „Přijímat soubory cookies z webů“; a v Chromu
            (verze 29) můžete blokovat všechny soubory cookies v nabídce
            „Přizpůsobení a ovládání“, kde klikněte na „Nastavení“, „Zobrazit
            pokročilá nastavení“ a „Nastavení obsahu“ a pak pod nadpisem
            „Cookies“ vyberte „Blokovat webům možnost nastavení jakýchkoliv
            údajů“. Blokování všech souborů cookies bude mít negativní dopad na
            použitelnost mnoha webových stránek. Pokud soubory cookies
            zablokujete, nebudete na našich webových stránkách moci využít
            všechny funkce.
            <br />
            <br />
            Soubory cookies již uložené ve vašem počítači můžete vymazat –
            například: v Internet Exploreru (verze 10) musíte soubory cookies
            vymazat ručně (instrukce jak to provést naleznete zde:
            http://support.microsoft.com/kb/278835); ve Firefoxu (Verze 24)
            můžete soubory cookies smazat kliknutím na „Nástroje“, „Možnosti“ a
            „Soukromí“, pak vyberete „Použít vlastní nastavení historie“,
            kliknete na „Zobrazit soubory cookies“ a pak kliknete na „Odstranit
            všechny soubory cookies“; a v Chromu (verze 29) můžete všechny
            soubory cookies smazat v nabídce „Přizpůsobení a ovládání“, kde
            klikněte na „Nastavení“, „Zobrazit pokročilá nastavení“ a „Vymazat
            data prohlížeče“ a před tím, než kliknete na „Vymazat data
            prohlížeče“, vyberete „Smazat soubory cookies a další data stránek a
            rozšíření“. Vymazání souborů cookies bude mít negativní dopad na
            použitelnost mnoha webových stránek.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Protection;
