const Footer = () => {
  return (
    <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
        <div className="sm:col-span-2">
          <a
            href="/"
            aria-label="Go home"
            title="Company"
            className="inline-flex items-center"
          >
            <svg
              className="w-8 text-cyan-accent-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
            <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">
              TRAVEL KLAUDIA
            </span>
          </a>
          <div className="mt-6 lg:max-w-sm">
            <p className="text-sm text-gray-800">Vaše dovolená bez starostí.</p>
            <p className="mt-4 text-sm text-gray-800">
              O nic se nemusíte starat pouze vyplníte pár informací a zbytek
              necháte na nás.
            </p>
          </div>
        </div>
        <div className="space-y-2 text-sm">
          <p className="text-base font-bold tracking-wide text-gray-900">
            Kontakt
          </p>
          <div className="flex">
            <p className="mr-1 text-gray-800">Telefon:</p>
            <a
              href="tel:+420737038579"
              aria-label="Telefon"
              title="Telefon"
              className="transition-colors duration-300 text-cyan-accent-400 hover:text-cyan-800"
            >
              +420 737 038 579
            </a>
          </div>
          <div className="flex">
            <p className="mr-1 text-gray-800">Email:</p>
            <a
              href="mailto:info@travelklaudia.cz"
              aria-label="Email"
              title="Email"
              className="transition-colors duration-300 text-cyan-accent-400 hover:text-cyan-800"
            >
              info@travelklaudia.cz
            </a>
          </div>
        </div>
        <div>
          <span className="text-base font-bold tracking-wide text-gray-900">
            Sociální sítě
          </span>
          <div className="flex items-center mt-1 space-x-3">
            <a
              href="https://www.facebook.com/travelklaudia/"
              className="text-gray-500 transition-colors duration-300 hover:text-cyan-accent-400"
            >
              <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
              </svg>
            </a>
            <a
              href="https://www.instagram.com/travelklaudiacz/"
              className="text-gray-500 transition-colors duration-300 hover:text-cyan-accent-400"
            >
              <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                <circle cx="15" cy="15" r="4" />
                <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
        <p className="text-sm text-gray-600">
          © 2021 TRAVEL KLAUDIA. Všechna práva vyhrazena.
        </p>
        <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
          <li>
            <a
              href="/"
              className="text-sm text-gray-600 transition-colors duration-300 hover:text-cyan-accent-400"
            >
              F.A.Q
            </a>
          </li>
          <li>
            <a
              href="/protection"
              className="text-sm text-gray-600 transition-colors duration-300 hover:text-cyan-accent-400"
            >
              Zásady ochrany osobních údajů
            </a>
          </li>
          <li>
            <a
              href="/terms"
              className="text-sm text-gray-600 transition-colors duration-300 hover:text-cyan-accent-400"
            >
              Všeobecné obchodní podmínky
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
