import Klaudia1 from "assets/images/about/klaudia1.jpg";
import Klaudia2 from "assets/images/about/klaudia2.jpg";
import Klaudia3 from "assets/images/about/klaudia3.jpg";
import Klaudia4 from "assets/images/about/klaudia4.jpg";
import Klaudia5 from "assets/images/about/klaudia5.jpg";
import Klaudia6 from "assets/images/about/klaudia6.jpg";
import Klaudia7 from "assets/images/about/klaudia7.jpg";
import Klaudia8 from "assets/images/about/klaudia8.jpg";
import Klaudia9 from "assets/images/about/klaudia9.jpg";

const Content = () => {
  return (
    <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-20">
      <div className="mx-auto sm:text-center lg:max-w-4xl">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-4xl md:mb-12">
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="5dc90b42-5ed4-45a6-8e63-2d78ca9d3d95"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#5dc90b42-5ed4-45a6-8e63-2d78ca9d3d95)"
                  width="52"
                  height="24"
                />
              </svg>
            </span>
            Ahoj jmenuji se Klaudie a&nbsp;vítám vás na svých stránkách !
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
            Ráda bych vám o sobě něco ve zkratce napsala abychom se lépe poznali
            . Mezi mé vášnivé koníčky mimo jiných aktivit patří cestování ,
            potápění a focení. Od svých 6 let kdy mě poprvé vzali rodiče na
            dovolenou do Chorvatska jsem si sluníčko a moře naprosto zamilovala
            . Do moře jsem ale nikdy bez nafukovacího lehátka až do svých 21 let
            nevzlezla ... momentálně mi je 27 let 😀 . Měla jsem hrůzu ze
            žraloků a všeho co v moři žije. Pokaždé, když jsem chtěla jít do
            vody jsem si představila scénu kdy se z modré hlubiny vynoří
            obrovský žralok nebo se něco dotkne mé nohy a radši jsem se na moře
            jen dívala 😀. Teď patří potápění mezi mé nejoblíbenější koníčky a
            za to vděčím svému příteli, který mě zbavil zbytečného strachu a
            ukázal jak překrásný podmořský život je.
          </p>
        </div>
        <div className="mb-4 transition-shadow duration-300 hover:shadow-xl lg:mb-6">
          <img
            className="object-cover w-full h-56 rounded shadow-lg sm:h-64 md:h-80 lg:h-96"
            src={Klaudia2}
            alt="Travel Klaudia"
          />
        </div>
        <p className="max-w-xl mb-4 text-base text-gray-700 sm:mx-auto">
          Díky cestování jsem poznala mnoho různých kultur, zajímavých lidí,
          míst a tradic. Miluji džungli a vše co v ní žije !
        </p>
        <div className="grid max-w-screen-lg gap-8 row-gap-5 mb-8 sm:grid-cols-2 lg:grid-cols-4 sm:mx-auto">
          <img
            className="object-cover w-full h-56 rounded shadow-lg"
            src={Klaudia1}
            alt="Travel Klaudia"
          />
          <img
            className="object-cover w-full h-56 rounded shadow-lg"
            src={Klaudia3}
            alt="Travel Klaudia"
          />
          <img
            className="object-cover w-full h-56 rounded shadow-lg"
            src={Klaudia4}
            alt="Travel Klaudia"
          />
          <img
            className="object-cover w-full h-56 rounded shadow-lg"
            src={Klaudia5}
            alt="Travel Klaudia"
          />
        </div>
        <p className="mb-4 text-base text-gray-700 sm:mx-auto">
          Poprvé jsem před pár lety bez cestovky letěla s kamarádkou na
          Zakynthos . Neuměly jsme ani slovo anglicky a poprvé jsem si vše
          musela zařídit sama . Za další měsíc jsme letěli na Bali . Nebyla jsem
          na tom zrovna moc dobře po finanční stránce ale podařilo se mi vyřešit
          dovolenou za opravdu dobré peníze . Nikdy bych nevěřila že tuhle
          destinaci někdy navštívím a víte co ? Za částku, kterou si cestovní
          kanceláře účtují na pár dní jsem tam byla už dvakrát . Od té doby už
          jen bez cestovky ! Ušetřila jsem opravdu spoustu peněz a mohla tak
          prozkoumat více míst . Teď se snažím využít své znalosti a zkušenosti
          a pomoct tak ostatním aby se také mohli podívat tam, kam by chtěli a
          splnit si svůj sen. Věnuji se tomu už nějakou dobu a pomohla jsem
          ušetřit peníze spoustu lidem tak mě napadlo že si udělám tuhle stránku
          a začnu pomáhat více lidem 😊. Přítel létá s padákem, tak si mnoho
          destinací můžeme prohlédnout i ze shora,takže kdyby jste na nás někde
          narazili máte jeden let zdarma 😀
        </p>
        <div className="grid max-w-screen-lg gap-8 row-gap-5 mb-8 sm:grid-cols-2 lg:grid-cols-4 sm:mx-auto">
          <img
            className="object-cover w-full h-56 rounded shadow-lg"
            src={Klaudia6}
            alt="Travel Klaudia"
          />
          <img
            className="object-cover w-full h-56 rounded shadow-lg"
            src={Klaudia7}
            alt="Travel Klaudia"
          />
          <img
            className="object-cover w-full h-56 rounded shadow-lg"
            src={Klaudia8}
            alt="Travel Klaudia"
          />
          <img
            className="object-cover w-full h-56 rounded shadow-lg"
            src={Klaudia9}
            alt="Travel Klaudia"
          />
        </div>
        <p className="mb-4 text-base text-gray-700 sm:mx-auto">
          Jinak jsem za svůj život vyzkoušela hodně zajímavých věcí... od
          modelingu, focení, natáčení až po hostesing u kterého jsem zůstala .
          Už to bude letos 5 let . Ráda zkouším nové věci a jdu do každé
          šílenosti! 😀
        </p>
        <p className="mb-4 text-base text-gray-700 sm:mx-auto">
          Těším se na naší spolupráci 😀 !
        </p>
        <a
          href="/request"
          aria-label=""
          className="inline-flex items-center font-semibold transition-colors duration-200 text-cyan-accent-400 hover:text-cyan-800"
        >
          Přejít na poptávku
          <svg
            className="inline-block w-3 ml-2"
            fill="currentColor"
            viewBox="0 0 12 12"
          >
            <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
          </svg>
        </a>
      </div>
    </div>
  );
};

export default Content;
