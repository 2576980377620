import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "containers/Home";
import About from "containers/About";
import Contact from "containers/Contact";
import Protection from "containers/Protection";
import Post from "components/Blog/Post";
import Request from "containers/Request";
import NotFound from "containers/NotFound";
import LoadingBar from "react-top-loading-bar";
import Nav from "components/Nav/Nav";
import Blog from "containers/Blog";
import Footer from "components/Footer/Footer";
import Terms from "containers/Terms";

function App() {
  return (
    <>
      <LoadingBar waitingTime={500} color="rgb(0, 229, 255)" progress={100} />
      <Router>
        <Nav />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/request" component={Request} />
          <Route exact path="/blog/" component={Blog} />
          <Route exact path="/blog/:uid" component={Post} />
          <Route exact path="/about-us" component={About} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/protection" component={Protection} />
          <Route exact path="/terms" component={Terms} />
          <Route component={NotFound} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
