import { useState } from "react";
import { useForm } from "react-hook-form";

function Request() {
  const [showAlert, setShowAlert] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitted, isSubmitSuccessful },
  } = useForm();

  const onSubmit = async (data: any) => {
    const res = await fetch("/api/sendEmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    setShowAlert(true);

    if (res.status === 200) {
      return console.log("resolved");
    }

    return console.log("rejected");
  };

  return (
    <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-20">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-4xl md:mb-12">
        <h1 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          Poptávka
        </h1>
      </div>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <p className="mt-1 text-sm text-gray-600">
                Prosíme o vyplnění informací k vaší dovolené:
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            {!isSubmitSuccessful && !showAlert && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="shadow overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="firstname"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Jméno
                        </label>
                        <input
                          type="text"
                          id="firstname"
                          autoComplete="given-name"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm border-gray-300 rounded-md"
                          {...register("firstname", {
                            required: true,
                            maxLength: 80,
                          })}
                        />
                        <p className="text-red-500 text-xs italic">
                          {errors.firstname?.message}
                        </p>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="lastName"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Příjmení
                        </label>
                        <input
                          type="text"
                          id="lastName"
                          autoComplete="family-name"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm border-gray-300 rounded-md"
                          {...register("lastName", {
                            required: true,
                            maxLength: 100,
                          })}
                        />
                        <p className="text-red-500 text-xs italic">
                          {errors.lastName?.message}
                        </p>
                      </div>

                      <div className="col-span-6 sm:col-span-4">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          E-mail
                        </label>
                        <input
                          type="text"
                          id="email"
                          autoComplete="email"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm border-gray-300 rounded-md"
                          {...register("email", {
                            required: true,
                            pattern: /^\S+@\S+$/i,
                          })}
                        />
                        <p className="text-red-500 text-xs italic">
                          {errors.email?.message}
                        </p>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="Telefon"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Telefon
                        </label>
                        <input
                          type="tel"
                          id="telefon"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm border-gray-300 rounded-md"
                          {...register("telefon", {
                            required: true,
                            maxLength: 12,
                          })}
                        />
                        <p className="text-red-500 text-xs italic">
                          {errors.telefon?.message}
                        </p>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="osob"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Počet osob
                        </label>
                        <input
                          type="number"
                          id="osob"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm border-gray-300 rounded-md"
                          {...register("osob", { required: true, min: 1 })}
                        />
                        <p className="text-red-500 text-xs italic">
                          {errors.osob?.message}
                        </p>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="cil"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Cílová destinace
                        </label>
                        <input
                          type="text"
                          id="cil"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm border-gray-300 rounded-md"
                          {...register("cil", {
                            required: true,
                          })}
                        />
                        <p className="text-red-500 text-xs italic">
                          {errors.cil?.message}
                        </p>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="odletz"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Odlet z
                        </label>
                        <input
                          type="text"
                          id="odletz"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm border-gray-300 rounded-md"
                          {...register("odletz", {
                            required: true,
                          })}
                        />
                        <p className="text-red-500 text-xs italic">
                          {errors.odletz?.message}
                        </p>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="odlet"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Datum odletu
                        </label>
                        <input
                          type="date"
                          placeholder=""
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm border-gray-300 rounded-md"
                          {...register("odlet", { required: true })}
                        />
                        <p className="text-red-500 text-xs italic">
                          {errors.odlet?.message}
                        </p>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="prilet"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Datum příletu
                        </label>
                        <input
                          type="date"
                          placeholder=""
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm border-gray-300 rounded-md"
                          {...register("prilet", { required: true })}
                        />
                        <p className="text-red-500 text-xs italic">
                          {errors.prilet?.message}
                        </p>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="noc"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Počet nocí
                        </label>
                        <input
                          type="number"
                          id="noc"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm border-gray-300 rounded-md"
                          {...register("noc", { required: true, min: 1 })}
                        />
                        <p className="text-red-500 text-xs italic">
                          {errors.noc?.message}
                        </p>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="cena"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Cena za noc
                        </label>
                        <select
                          {...register("cena", { required: true })}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm border-gray-300 rounded-md"
                        >
                          <option value="0 – 1 350 Kč">0 – 1 350 Kč</option>
                          <option value="1 350 – 2 600 Kč">
                            1 350 – 2 600 Kč
                          </option>
                          <option value="2 600 – 3 900 Kč">
                            2 600 – 3 900 Kč
                          </option>
                          <option value="3 900 – 5 000 Kč">
                            3 900 – 5 000 Kč
                          </option>
                          <option value="5 000 Kč a více">
                            5 000 Kč a více
                          </option>
                        </select>
                        <p className="text-red-500 text-xs italic">
                          {errors.cena?.message}
                        </p>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="strava"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Stava
                        </label>
                        <select
                          {...register("strava", { required: true })}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm border-gray-300 rounded-md"
                        >
                          <option value="Bez stravy">Bez stravy</option>
                          <option value="Snídaně">Snídaně</option>
                          <option value="Snídaně a obědy">
                            Snídaně a obědy
                          </option>
                          <option value="Snídaně a večeře">
                            Snídaně a večeře
                          </option>
                          <option value="All inclusive">All inclusive</option>
                        </select>
                        <p className="text-red-500 text-xs italic">
                          {errors.strava?.message}
                        </p>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="ubytovani"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Typ ubytování
                        </label>
                        <select
                          {...register("ubytovani", { required: true })}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm border-gray-300 rounded-md"
                        >
                          <option value="Hotel">Hotel</option>
                          <option value="Rezort">Rezort</option>
                          <option value="Penzion">Penzion</option>
                          <option value="Pronájem celého ubytování">
                            Pronájem celého ubytování
                          </option>
                        </select>
                        <p className="text-red-500 text-xs italic">
                          {errors.ubytovani?.message}
                        </p>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="pokoj"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Počet pokojů
                        </label>
                        <select
                          {...register("pokoj", { required: true })}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm border-gray-300 rounded-md"
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </select>
                        <p className="text-red-500 text-xs italic">
                          {errors.pokoj?.message}
                        </p>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="postel"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Počet postelí
                        </label>
                        <select
                          {...register("postel", { required: true })}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm border-gray-300 rounded-md"
                        >
                          <option value="1 a více">1 a více</option>
                          <option value="2 a více">2 a více</option>
                          <option value="3 a více">3 a více</option>
                          <option value="4 a více">4 a více</option>
                          <option value="5 a více">5 a více</option>
                        </select>
                        <p className="text-red-500 text-xs italic">
                          {errors.postel?.message}
                        </p>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="typ"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Preferovaný typ postelí
                        </label>
                        <select
                          {...register("typ", { required: true })}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm border-gray-300 rounded-md"
                        >
                          <option value="Dvě oddělené postele">
                            Dvě oddělené postele
                          </option>
                          <option value="Manželská postel">
                            Manželská postel
                          </option>
                        </select>
                        <p className="text-red-500 text-xs italic">
                          {errors.typ?.message}
                        </p>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="plaz"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Přístup na pláž
                        </label>
                        <select
                          {...register("plaz", { required: true })}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm border-gray-300 rounded-md"
                        >
                          <option value="U pláže">U pláže</option>
                          <option value="Do 500 m">Do 500 m</option>
                          <option value="Do 1 km">Do 1 km</option>
                          <option value="Nezáleží">Nezáleží</option>
                        </select>
                        <p className="text-red-500 text-xs italic">
                          {errors.plaz?.message}
                        </p>
                      </div>

                      <div className="col-span-12 sm:col-span-6">
                        <label
                          htmlFor="poznamky"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Poznámky
                        </label>
                        <textarea
                          id="poznamky"
                          {...register("poznamky", {})}
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 sm:px-6">
                    <button
                      type="submit"
                      disabled={isSubmitted}
                      className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-cyan-accent-400 hover:bg-cyan-accent-700 focus:shadow-outline focus:outline-none"
                    >
                      Odeslat
                    </button>
                  </div>
                </div>
              </form>
            )}
            {isSubmitSuccessful && showAlert ? (
              <div
                className={
                  "text-white px-6 py-4 border-0 rounded relative mb-4 bg-cyan-500"
                }
              >
                <span className="text-xl inline-block mr-5 align-middle">
                  <i className="fas fa-bell" />
                </span>
                <span className="inline-block align-middle mr-8">
                  Děkujeme. Ozveme se Vám!
                </span>
                <button
                  className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
                  onClick={() => {
                    setShowAlert(false);
                    reset();
                  }}
                >
                  <span>×</span>
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Request;
